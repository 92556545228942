<template>
    <div class="container mt-5 pt-3">
        <div class="row mt-5 mt-lg-0">
            <div class="col-12 col-lg-8">
                <h3 class="mb-3 mb-lg-4 ">Information (Mombamomba)</h3>
                <div class="d-flex">
                    <p class="titre_C">Match <span class="du">du</span> :</p>
                    <div class="d-flex date-display ms-2">{{ currentDate }}</div>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="cadre_btn">
                    <button class="btn_h_p">6h - 8h</button>
                    <button class="btn_h_p">8h - 10h</button>
                    <button class="btn_h_p">10h - 12h</button>
                    <button class="btn_h_p">12h - 14h</button>
                    <button class="btn_h_p">14h - 16h</button>
                    <button class="btn_h_p">16h - 18h</button>
                    <button class="btn_h_p">18h - 20h</button>
                </div>
            </div>
        </div>
        <div class="row mt-2 mt-lg-3">
            <div class="col-12 col-lg-3">
                <div class="bordure_exte">
                    <legend>Equipe</legend>
                    <p class="nom_info">{{ equipe.nomEquipe }}</p>
                </div>
            </div>
            <div class="col-12 col-lg-3">
                <div class="bordure_exte">
                    <legend>Responsable</legend>
                    <p class="nom_info">{{ responsable.nomResponsable }}</p>
                </div>
            </div>
        </div>
        <div class="row mt-2 mt-lg-3">
            <div class="col-12">
                <legend>Liste joueurs / CIN</legend>
                <table class=" table table-hover">
                    <thead class="">
                        <tr>
                            <th class="color-text tableau">Nom des joueurs (Anaran'ireo mpilalao)</th>
                            <th class="tableau">CIN (Lah Karapanondro)</th>
                            <th class="tableau">Présences (Fanamariham-pahatongavana)</th>
                        </tr>
                    </thead>
                    <tbody class="">
                        <tr v-for="joueur in joueurs" :key="joueur.cinJoueur">
                            <td class="tableau">{{ joueur.nomJoueur }}</td>
                            <td class="tableau">{{ joueur.cinJoueur }}</td>
                            <td class="tableau d-flex">
                                <label for="">P</label>
                                <input type="checkbox" class="inpt_checkbox_p">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mt-2 mt-lg-3">
            <h4>Rapport (Tatitra)</h4>
            <form action="" method="post">
                <textarea name="" class="form-control" id="" cols="30" rows="5"
                    placeholder="Entrer votre rapport (Soraty eto ireo fanamarihana)">
                </textarea>
                <input class="btn mt-2" type="submit" value="Envoyer">
            </form>
        </div>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import axios from 'axios';
import * as XLSX from 'xlsx';


// import Calendrier_sans_selection from './Calendrier_sans_selection.vue';

export default {
    props: {
        id_equipe: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            currentDate: this.getCurrentDate(),
            responsable: {
                idResponsable: '',
                nomResponsable: '',
                telResponsable: '',
                emailResponsable: '',
            },
            equipe: {
                nomEquipe: '',
            },
            joueurs: [],
        };
    },
    // components: {
    //     Calendrier_sans_selection,
    // },
    created() {
        console.log("id_equipe dans PosteInfo : ", this.id_equipe);
    },
    mounted() {
        this.fetchEquipeData();
    },
    methods: {
        getCurrentDate() {
            const date = new Date();
            return this.formatDate(date);
        },
        formatDate(date) {
            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'long' });
            const year = date.getFullYear();
            return `${day} ${month} ${year}`;
        },
        previousDate() {
            const dateParts = this.currentDate.split(' ');
            const day = parseInt(dateParts[0], 10);
            const month = dateParts[1];
            const year = parseInt(dateParts[2], 10);

            let currentDate = new Date(year, this.getMonthNumber(month), day);
            currentDate.setDate(currentDate.getDate() - 1);

            this.currentDate = this.formatDate(currentDate);
        },
        nextDate() {
            const dateParts = this.currentDate.split(' ');
            const day = parseInt(dateParts[0], 10);
            const month = dateParts[1];
            const year = parseInt(dateParts[2], 10);

            let currentDate = new Date(year, this.getMonthNumber(month), day);
            currentDate.setDate(currentDate.getDate() + 1);

            this.currentDate = this.formatDate(currentDate);
        },
        getMonthNumber(monthName) {
            const months = {
                'janvier': 0, 'février': 1, 'mars': 2, 'avril': 3, 'mai': 4, 'juin': 5,
                'juillet': 6, 'août': 7, 'septembre': 8, 'octobre': 9, 'novembre': 10, 'décembre': 11
            };
            return months[monthName.toLowerCase()];
        },
        async fetchEquipeData() {
            try {
                const response = await axios.get(`http://127.0.0.1:8000/api/equipes/${this.id_equipe}`);
                const data = response.data;
                this.equipe = {
                    nomEquipe: data.nomEquipe,
                };
                this.responsable = {
                    nomResponsable: data.nomResponsable,
                };
                this.joueurs = data.joueurs;
                console.log(this.equipe, this.responsable, this.joueurs); // Vérifiez les données dans la console pour assurer qu'elles sont correctes
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        },
        async generateAndSendExcel() {
  // Collect data from input fields
  const data = [
    {
      Responsable: this.nomResponsable,
      Equipe: this.nomEquipe,
      Joueurs: this.listeJoueurs.map(joueur => joueur.nom).join(', '),
      Rapport: this.rapport
    }
  ];

  // Create a worksheet
  const ws = XLSX.utils.json_to_sheet(data);

  // Create a workbook
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Rapport');

  // Write to Excel and trigger a download
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  
  // Convert blob to file
  const file = new File([blob], 'rapport.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  // Send file to the backend
  const formData = new FormData();
  formData.append('file', file);

  try {
    await axios.post('http://127.0.0.1:8000/api/send-email', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    alert('Email sent successfully!');
  } catch (error) {
    console.error('Error sending email:', error);
  }
}


    }
};
</script>
<style scoped>
.date-display {
    font-size: 18px;
    font-weight: lighter;
    background-color: #41A62A;
    padding: 5px;
    border-radius: 10px;
    margin-top: -10px;
    color: white;
    margin-bottom: 8px;
}

.btn {
    background-color: #41A62A;
    color: white;
}

.titre_C {
    font-weight: bold;
}

.du {
    font-weight: lighter;
}

.tableau {
    /* display: flex;
    flex-direction: column; */
    border: 1px solid#D9D9D9;
}



.btn_h_p {

    /* Pour que les boutons prennent le même espace */
    min-width: 10%;
    /* Largeur maximale des boutons */
    margin: 5px;
    margin-left: 0;
    /* Marge autour des boutons */
    padding: 1%;
    /* Espacement à l'intérieur des boutons */
    text-align: center;
    /* Centrer le texte horizontalement */
    cursor: pointer;
    /* Curseur pointer pour indiquer qu'il s'agit d'un élément cliquable */
    border: 1px solid #D9D9D9;
    /* Supprimer la bordure */
    border-radius: 5px;
    /* Coins arrondis */
    font-size: 70%;
    background-color: white;
    color: black;
}

.bordure_exte {
    border: 1px solid#D9D9D9;
    padding: 10px;
    border-radius: 10px;
}

legend {
    color: #41A62A;
}

.nom_info {
    color: #808080;
    font-size: large;
    margin-left: 5%;
}
</style>