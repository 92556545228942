<template>
    <div class="fond-arrière_admin_liste">
     
        <Test_liste_persVue></Test_liste_persVue>

    </div>
</template>

<script>



import Test_liste_persVue from './Test_liste_pers.vue';
export default {
    components: {
    
      
        Test_liste_persVue
    }
}
</script>

<style lang="css" scoped>

</style>