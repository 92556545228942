<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-lg-5 p-3 text-center d-none d-lg-block fixed-top">
                <div class="bordur p-3">
                    <img class="img-fluid logo" src="../../assets/image/A5_-_1-removebg-preview.png" alt="">
                    <h1 class="mb-4">Formulaire</h1>
                    <div class="rond text-center pt-2 mb-2">1</div>
                    <p class="t_etape">Details clients</p>
                    <div class="ligne mb-4"></div>
                    <div class="rond text-center pt-2 mb-2">2</div>
                    <p class="t_etape">Réservation</p>
                    <div class="ligne mb-4 "></div>
                    <div class="rond1 text-center pt-2 mb-2">3</div>
                    <p class="t_etape">Payement</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-5">

            </div>
            <div class="col-12 col-lg-5">
                <h1>Votre réservation</h1>
                <div class="boite">
                    <h5 class="t_class">Types de réservation</h5>
                    <div class="d-flex flex-row">
                        <p>Réserver par</p>
                    <select class="selection ms-3" v-model="selectedMode" @change="changeMode">
                        <option disabled value="">Sélectionnez un type</option>
                        <option value="day">Jour</option>
                        <option value="month">Mois</option>
                    </select>
                    </div>
                </div>
                <div class="boite1 d-flex flex-row mt-3">
                    <h5 class="t_class">Date de session</h5>
                    <div class="d-flex flex-row">
                        <button class="btn btn_prev" @click="prevMonth">&lt;</button>
                        <h5 class="months_year ms-2 me-3 mt-lg-0 mt-4">{{ currentMonthName }} {{ year }}</h5>
                        <button class="btn btn_next" @click="nextMonth">&gt;</button>
                    </div>
                </div>
                <div class="d-flex flex-direction-row">
                    <div class="p_cadre_I me-2"></div>
                    <p class="text_couleur me-2">Indisponnible</p>
                    <div class="p_cadre_D me-2"></div>
                    <p>Disponnible</p>
                </div>

                <div class="weekdays">
                    <div v-for="day in weekdays" :key="day" class="weekday">{{ day }}</div>
                </div>
                <div class="days">
                    <div v-for="day in daysInMonth" :key="`${day.day}-${day.month}-${day.year}`" :class="[
                            'day',
                            { 'highlight': isWeekend(day.day) },
                            { 'selected': isSelected(day) },
                            { 'empty': !day.day },
                            { 'hovered': day.hovered },
                            { 'reserved-date': isDateReserved(day) }
                           
                        ]" @click="toggleDate(day)" @mouseover="day.hovered = true" @mouseleave="day.hovered = false">
                        {{ day.day }}
                    </div>
                </div>
                <div v-if="showHours && selectedDate" class="hours">
                    <h5>Heures de session :</h5>
                    <button class="btn" v-for="hour in availableHoursForSelectedDate(selectedDate.split('/')[0])" :key="hour"
                        @click="toggleHour(hour)" :class="{ 'selected': isSelectedHour(hour) }">
                        {{ hour }}
                    </button>
                </div>

            </div>

            <div class="col-12 col-lg-2">
                <div class="selected-dates-hours" v-if="selectedDates.length">
                    <h3 class="titre_d_t_select">Date et heure sélectionnées :</h3>
                    <ul>
                        <li v-for="date in selectedDates" :key="date">
                            <span>{{ date }}</span>
                            <span v-if="selectedDateTime[date]"> || {{ selectedDateTime[date].join(', ') }}</span>
                        </li>
                    </ul>
                    <div class="participation">
                        <p><span>Participation</span> : {{ participation }} Ar</p>
                    </div>
                </div>
                <button class="btn bg_fond mt-2" @click="submitReservation">Suivant</button>
            </div>
        </div>
    </div>
</template>

<script>
import routes from '../router'
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
export default {
  name: 'DateCalendar',
  data() {
    const date = new Date();
    const monthIndex = date.getMonth();
    return {
      mode:'day',
      year: date.getFullYear(),
      currentMonthIndex: monthIndex,
      currentMonthName: months[monthIndex],
      weekdays: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'],
      selectedDates: [],           // Liste des dates sélectionnées
      showHours: false,            // Affichage des heures (si l'heure est définie ou non)
      participation: 0,            // Participation (si nécessaire dans votre logique)
      selectedDateTime: {},        // Dictionnaire des heures sélectionnées pour chaque date
      selectedMode: 'day',         // Mode initial : jour (day)
     
      
      reservations: [],
    };
  },
  mounted() {
    this.fetchEquipeData();
    this.submitReservation();
    
  },
  computed: {
    reservedDates() {
      return this.reservations.map(reservation => reservation.dateReservation);
    },
    daysInMonth() {
      const lastDayOfMonth = new Date(this.year, this.currentMonthIndex + 1, 0).getDate();
      let days = [];
      const firstDayOfMonth = new Date(this.year, this.currentMonthIndex, 1).getDay();
      const startDay = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;
      const prevMonthLastDay = new Date(this.year, this.currentMonthIndex, 0).getDate();
      const startDayPrevMonth = prevMonthLastDay - startDay + 1;

      for (let i = startDayPrevMonth; i <= prevMonthLastDay; i++) {
        days.push({ day: '', month: this.currentMonthIndex - 1, year: this.year });
      }

      for (let i = 1; i <= lastDayOfMonth; i++) {
        days.push({ day: i, month: this.currentMonthIndex, year: this.year });
      }

      const daysNeeded = 35 - days.length;
      for (let i = 1; i <= daysNeeded; i++) {
        days.push({ day: '', month: this.currentMonthIndex + 1, year: this.year });
      }

      return days;
    }
  },
  methods: {
    // isHourReserved(hour, reservation) {
    //   return reservation.includes(hour);
    // },
  
    isHourReserved(hour) {
      if (!this.selectedDate) return false;

      const selectedDate = this.selectedDate;
      const reservation = this.reservations.find(r => r.dateReservation === selectedDate);

      if (reservation && reservation.heureReservation.includes(hour)) {
        return true;
      }

      // Condition pour empêcher les réservations de 12h à 14h le mercredi et le vendredi
      const dateObj = new Date(this.year, this.currentMonthIndex, parseInt(selectedDate.split('/')[0]));
      const weekday = dateObj.getDay();

      if ((weekday === 3 || weekday === 5) && (hour === '12h - 14h')) {
        return true;
      }

      return false;
    },
    isHeureReservationEqualForDate(heureReservation, day, month, year) {
      return this.reservations.some(reservation => {
        return (
          reservation.dateReservation === `${day}/${month}/${year}` &&
          reservation.heureReservation === heureReservation
        );
      });
    },
    resetParticipationForDayMode() {
      if (this.selectedMode === 'day') {
        this.participation = 0;
      }
    },
    resetParticipation() {
      this.participation = 0;
    },
    calculateParticipation() {
      const selectedHoursCount = Object.values(this.selectedDateTime).flat().length;
      this.participation = selectedHoursCount * 15000;
    },
    redirectToInformEquipe() {
      routes.push('/Payement')
    },
  changeMode() {
      this.selectedDates = [];    // Réinitialiser les dates sélectionnées
      this.showHours = false;     // Réinitialiser l'affichage des heures
      this.participation = 0;     // Réinitialiser la participation
      this.selectedDateTime = {}; // Réinitialiser les heures sélectionnées
      // Pas besoin de changer l'affichage du calendrier, mais on gère la logique derrière
    },

    isWeekend(day) {
      const date = new Date(this.year, this.currentMonthIndex, day);
      const weekday = date.getDay();
      return weekday === 0 || weekday === 6;
    },
    prevMonth() {
      if (this.currentMonthIndex > 0) {
        this.currentMonthIndex--;
      } else {
        this.currentMonthIndex = 11;
        this.year--;
      }
      this.currentMonthName = months[this.currentMonthIndex];
    },
    nextMonth() {
      if (this.currentMonthIndex < 11) {
        this.currentMonthIndex++;
      } else {
        this.currentMonthIndex = 0;
        this.year++;
      }
      this.currentMonthName = months[this.currentMonthIndex];
    },
    toggleHour(hour) {
      const isHourSelected = this.isSelectedHour(hour);
      let selectedHoursForDate = this.selectedDateTime[this.selectedDate] || [];

      if (isHourSelected) {
        selectedHoursForDate = selectedHoursForDate.filter(h => h !== hour);
      } else {
        // Vérifier si l'heure est déjà réservée
        if (this.isHourReserved(hour)) {
          alert("Cette heure est déjà réservée.");
          return; // Ne pas ajouter l'heure sélectionnée
        }
        selectedHoursForDate.push(hour);
      }

      this.selectedDateTime[this.selectedDate] = selectedHoursForDate;
      this.updateParticipation();
    },
    toggleDate(day) {
      if (this.selectedMode === 'day') {
        this.toggleDateDayMode(day);
    } else if (this.selectedMode === 'month') {
    this.toggleDateMonthMode(day);
    }
},
   toggleDateDayMode(day) {
  if (day.day !== '') {
    const selectedDateStr = `${day.day}/${this.currentMonthIndex + 1}/${this.year}`;

    // Si une date est déjà sélectionnée et que c'est une nouvelle date, on la remplace
    if (this.selectedDates.length === 0 || this.selectedDates[0] !== selectedDateStr) {
      this.selectedDates = [selectedDateStr];
      this.showHours = true;
      this.selectedDateTime[selectedDateStr] = []; // Réinitialiser les heures pour cette nouvelle date
      this.participation = 0; // Réinitialiser la participation pour cette date
    } else {
      // Si la même date est sélectionnée à nouveau, on la désélectionne
      this.selectedDates = [];
      this.showHours = false;
      this.selectedDateTime = {}; // Réinitialiser les heures
    }

    this.selectedDate = this.selectedDates[0];
  }
},



   toggleDateMonthMode(day) {
      if (day.day !== '') {
        const selectedDateStr = `${day.day}/${this.currentMonthIndex + 1}/${this.year}`;
        const currentDate = new Date();
        const selectedDate = new Date(this.year, this.currentMonthIndex, day.day);

        if (selectedDate < currentDate) {
          alert("Vous ne pouvez pas sélectionner une date passée.");
          return;
        }

        this.selectedHour = [];
        const previousDatesHaveHours = this.selectedDates.every(date => this.selectedDateTime[date] && this.selectedDateTime[date].length > 0);

        if (this.selectedDates.length > 0 && !previousDatesHaveHours) {
          alert('Veuillez sélectionner au moins une heure pour chaque date précédente avant de choisir une nouvelle date.');
          return;
        }

        if (this.selectedDates.length === 0) {
          this.selectedDates = [selectedDateStr];
          this.showHours = true;
        } else {
          const index = this.selectedDates.indexOf(selectedDateStr);
          if (index !== -1) {
            this.selectedDates.splice(index, 1);
            this.showHours = false;
          } else {
            this.selectedDates.push(selectedDateStr);
            this.showHours = true;
          }
        }

        this.selectedDate = selectedDateStr;
        this.updateParticipation();
      }
    }

,

// Fonction pour définir une heure pour une date
selectTimeForDate(date, time) {
    const selectedDateStr = `${date.day}/${this.currentMonthIndex + 1}/${this.year}`;
    
    // Si l'heure est sélectionnée, on ajoute la date à selectedDateTime
    if (!this.selectedDateTime[selectedDateStr]) {
        this.selectedDateTime[selectedDateStr] = [];
    }

    // On empêche de sélectionner une heure avant d'avoir choisi une date
    if (this.selectedDates.length === 0) {
        alert("Veuillez d'abord sélectionner une date.");
        return;
    }

    this.selectedDateTime[selectedDateStr].push(time);
    this.showHours = true; // Afficher l'heure une fois qu'elle a été sélectionnée
},

    
  

   

    // Fonction pour afficher les heures associées à une date sélectionnée
    displaySelectedTimes(date) {
      const selectedDateStr = `${date.day}/${this.currentMonthIndex + 1}/${this.year}`;
      return this.selectedDateTime[selectedDateStr] || [];
    }
  

,
    isSelected(day) {
      const dateStr = `${day.day}/${this.currentMonthIndex + 1}/${this.year}`;
      return this.selectedDates.includes(dateStr);
    },
    isSelectedHour(hour) {
      const selectedHoursForDate = this.selectedDateTime[this.selectedDate] || [];
      return selectedHoursForDate.includes(hour);
    },
    availableHoursForSelectedDateWithReservations(dateStr) {
      const day = parseInt(dateStr.split('/')[0]);
      const month = this.currentMonthIndex;
      const year = this.year;
      const dateObj = new Date(year, month, day);
      const weekday = dateObj.getDay();
      let availableHours = [];

      if (weekday === 6 || weekday === 0) {
        availableHours = ['6h - 8h', '8h - 10h', '10h - 12h', '12h - 14h', '14h - 16h', '16h - 18h', '18h - 20h'];
      } else if (weekday >= 1 && weekday <= 5) {
        availableHours = ['6h - 8h', '12h - 14h', '16h - 18h', '18h - 20h'];
      }

      return availableHours.map(hour => ({
        time: hour,
        reserved: this.isHeureReservationEqual(hour),
      }));
    },
    availableHoursForSelectedDate(day) {
      const dateObj = new Date(this.year, this.currentMonthIndex, parseInt(day));
      const weekday = dateObj.getDay();

      if (weekday === 6) {
        return [
          '6h - 8h', '8h - 10h', '10h - 12h',
          '12h - 14h', '14h - 16h',
          '16h - 18h', '18h - 20h'
        ];
      }

      if (weekday === 0) {
        return [
          '6h - 8h', '8h - 10h', '10h - 12h', '12h - 14h',
          '14h - 16h', '16h - 18h',
          '18h - 20h'
        ];
      }

      if (weekday >= 1 && weekday <= 5) {
        return ['6h - 8h', '12h - 14h', '14h - 16h', '16h - 18h', '18h - 20h'];
      }

      return [];
    },
    updateParticipation() {
      if (this.selectedMode === 'day' && this.selectedDate) {
        const selectedHours = (this.selectedDateTime[this.selectedDate] || []).length;
        this.participation = selectedHours * 15000; // Chaque heure sélectionnée coûte 15000
      } else {
        const selectedHours = Object.values(this.selectedDateTime).flat().length;
        this.participation = selectedHours * 15000; // Chaque heure sélectionnée coûte 15000
      }
      console.log('Participation mise à jour:', this.participation);
    },
    async fetchEquipeData() {
      try {
        const response = await axios.get('http://127.0.0.1:8000/api/equipesB');
        const data = response.data;
        this.equipe = data.equipes[0];
        this.reservations = data.reservations;
        console.log(this.reservations); // Vérifiez le format des données dans la console
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      }
    },
    getToken() {
      // Récupère le jeton depuis le stockage local
      return localStorage.getItem('token');
    },
    isDateReserved(day) {
      const formattedDate = `${day.day}/${day.month + 1}/${day.year}`;
      return this.reservations.some(reservation => reservation.dateReservation === formattedDate);
    },
    async submitReservation() {
      try {
        // Vérifier si au moins une date est sélectionnée
        if (this.selectedDates.length === 0) {

          return;
        }

        // Formatage des dates et des heures de réservation
        const formattedDates = this.selectedDates.join(',');
        const formattedHours = {};
        this.selectedDates.forEach(date => {
          formattedHours[date] = this.selectedDateTime[date].join(',');
        });

        // Envoi de la requête à l'API
        const response = await axios.post('http://127.0.0.1:8000/api/reservations', {
          typeReservation: this.selectedMode,
          dateReservation: formattedDates,
          heureReservation: formattedHours,
          montanApayer: this.participation
        });

        console.log("Réponse du serveur:", response.data);
        alert('Réservation créée avec succès');
        this.redirectToInformEquipe();
      } catch (error) {
        console.error('Erreur lors de la création de la réservation:', error);
        if (error.response && error.response.data && error.response.data.error) {
          alert("Erreur de validation:\n" + JSON.stringify(error.response.data.error));
        } else {
          alert("Une erreur est survenue lors de la création de la réservation.");
        }
      }
    },
  }
}
</script>

<style scoped>
.logo {
    width: 20%;
    padding-top: 0px;
}

.bordur {
    /* border: 1px solid black; */
    background-color: #C6E4BF;
    border-radius: 10px;
    /* position: fixed; */
    height: 95vh;
}

.rond {
    background-color: #41A62A;
    color: white;
    border-radius: 50%;
    /* padding: 5%; */
    width: 40px;
    height: 40px;
    margin-left: 230px;
}

.rond1 {
    background-color: white;
    color: black;
    border-radius: 50%;
    /* padding: 5%; */
    width: 40px;
    height: 40px;
    margin-left: 230px;

}

.ligne {
    width: 5px;
    height: 75px;
    background-color: #41A62A;
    margin-left: 248px;
}


.t_etape {
    font-weight: bold;
}

.boite {
    border: 1px solid #C6E4BF;
    width: 250px;
    padding: 2%;
    border-radius: 10px;
}

.t_class {
    color: #41A62A;
}

.selection {
    border: none;
    outline: none;
    width: 60px;
    margin-top: -13px;
    height: 50px;
}

.btn {
    color: black;
    font-size: large;
}

.btn_prev {
    background-color: transparent;
    color: black;
    font-size: x-large;
    margin-top: -10px;
}

.btn_next {
    background-color: transparent;
    color: black;
    font-size: x-large;
    margin-left: -50
    
    px;
    margin-top: -10px;
}

.months_year {
    width: 200px;

}

.day {
    text-align: center;
    background-color: #D9D9D9;
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
}

.day.empty {
    background-color: transparent;
    /* fond transparent */
    color: white;
    /* texte en blanc */
}

.day.hovered {
    background-color: #C90119;
}

.days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 15px;
    border: none;
}

.day {
    text-align: center;
    background-color: #D9D9D9;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
    border: none;
}

.weekdays {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    /* margin-top: -10px; */
}

.weekday {
    flex: 0;
    text-align: center;
    font-weight: bold;
    color: #555;
    padding: 10px;
    border: none;
}

.bg_fond {
    color: white;
    background-color: #41A62A;
    width: 100%;
}

.p_cadre_I {
    width: 10%;
    height: 25px;
    background-color: #C90119;
}

.p_cadre_D {
    width: 10%;
    height: 25px;
    background-color: #D9D9D9;

}
.hours {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  margin-top: 20px;
}

.hours h3 {
  margin-bottom: 10px;
}

.hours button {
  /* padding: 2%;
  background-color: #007bff;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0.5%;
  flex-grow: 1;
  min-width: 120px; */
  padding: 2%;
  background-color: white;
  border: 1px solid;
  color: black;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 4px 4px 0 5px;
  /* Espacement pour séparer les boutons et du paragraphe */
  flex-grow: 1;
  width: 80px;
}

.hours button.selected {
  background-color: #C90119;
  color: white;
}
.reserved-date {
  background-color: #C90119;
  color: white;
}


</style>
