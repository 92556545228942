<template>
    <div class="fond-arrière_admin_liste">
        <Fond_gauche_admin></Fond_gauche_admin>
        <Navbar_Haut_Admin></Navbar_Haut_Admin>
        <GestionReservation></GestionReservation>
    </div>
</template>

<script>

import Fond_gauche_admin from './Fond_gauche_admin.vue'
import Navbar_Haut_Admin from './Navbar_Haut_Admin.vue';
import GestionReservation from './GestionReservation.vue'
export default {
    components: {
        Fond_gauche_admin,
        Navbar_Haut_Admin,
        GestionReservation,
    }
}
</script>

<style lang="css" scoped>

</style>