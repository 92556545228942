<template>
    <div class="container py-4">
        <div class="calendar card shadow p-4">
            <h1 class="text-center mb-4">Votre réservation</h1>

            <!-- Type de réservation -->
            <div class="mode-selector mb-3">
                <fieldset class="border p-3 rounded">
                    <legend class="w-auto px-2">Type de réservation</legend>
                    <p class="fw-bold text-primary">{{ reservations[0]?.typeReservation }}</p>
                </fieldset>
            </div>

            <!-- Navigation calendrier -->
            <div class="navigation d-flex justify-content-center align-items-center gap-3 mb-3">
                <button class="btn btn-outline-primary" @click="prevMonth">&lt;</button>
                <h2 class="mb-0">{{ currentMonthName }} {{ year }}</h2>
                <button class="btn btn-outline-primary" @click="nextMonth">&gt;</button>
            </div>

            <!-- Légende couleurs -->
            <div class="choix_couleur d-flex justify-content-center gap-3 mb-3">
                <div class="d-flex align-items-center">
                    <div class="p_cadre_I me-2 bg-danger rounded-circle" style="width: 20px; height: 20px;"></div>
                    <p class="mb-0">Indisponible</p>
                </div>
                <div class="d-flex align-items-center">
                    <div class="p_cadre_D me-2 bg-success rounded-circle" style="width: 20px; height: 20px;"></div>
                    <p class="mb-0">Disponible</p>
                </div>
            </div>

            <!-- Jours de la semaine -->
            <div class="weekdays row text-center fw-bold">
                <div v-for="day in weekdays" :key="day" class="col">{{ day }}</div>
            </div>

            <!-- Dates du mois courant -->
            <div class="Date_Heure">
                <div class="affichage_D_H row g-2">
                    <div class="days col-md-8">
                        <div class="row">
                            <div v-for="day in daysInMonth" :key="`${day.day}-${day.month}-${day.year}`"
                                class="col-1 text-center py-2 border rounded"
                                :class="[
                                    'day',
                                    { 'bg-secondary text-white': isWeekend(day.day) },
                                    { 'bg-primary text-white': isSelected(day) },
                                    { 'bg-light': !day.day },
                                    { 'hovered': day.hovered },
                                    { 'bg-danger text-white': isDateReserved(day) }
                                ]"
                                @click="toggleDate(day)"
                                @mouseover="day.hovered = true"
                                @mouseleave="day.hovered = false">
                                {{ day.day }}
                            </div>
                        </div>
                    </div>

                    <!-- Heures disponibles -->
                    <div v-if="showHours && selectedDate" class="hours col-md-4">
                        <h3 class="text-center">Heures de session :</h3>
                        <div class="btn-group-vertical w-100">
                            <button v-for="hour in availableHoursForSelectedDateWithReservations(selectedDate)"
                                :key="hour.time"
                                class="btn"
                                :class="{ 'btn-danger': hour.reserved, 'btn-outline-success': !hour.reserved }"
                                @click="toggleHour(hour.time)">
                                {{ hour.time }}
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Détails de l'équipe et heures réservées -->
                <div class="D_H_select mt-4">
                    <div class="nom_affiche_equipe text-center mb-3">
                        <p class="fw-bold">Nom d'équipe</p>
                    </div>
                    <div class="affiche_equipe d-flex align-items-center justify-content-center gap-2">
                        <img class="book" src="../../assets/Icon/book.svg" alt="" width="30">
                        <p class="fw-bold">{{ equipe.nomEquipe }}</p>
                    </div>

                    <div class="cadre_affiche_heure row g-2 mt-3">
                        <div class="h_affiche_g col-md-6">
                            <p class="text-center fw-bold">Heure de réservation</p>
                            <button class="btn w-100 my-1"
                                v-for="heure in ['6h - 8h', '8h - 10h', '10h - 12h', '12h - 14h']"
                                :key="heure"
                                :class="{ 'btn-danger': isHeureReservationEqual(heure), 'btn-outline-primary': !isHeureReservationEqual(heure) }">
                                {{ heure }}
                            </button>
                        </div>
                        <div class="h_affiche_d col-md-6">
                            <button class="btn w-100 my-1"
                                v-for="heure in ['14h - 16h', '16h - 18h', '18h - 20h']"
                                :key="heure"
                                :class="{ 'btn-danger': isHeureReservationEqual(heure), 'btn-outline-primary': !isHeureReservationEqual(heure) }">
                                {{ heure }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Bouton de réservation -->
            <div class="btn_src text-center mt-4">
                <button class="btn btn-success px-4" @click="redirectToReserveNouveau">Réserver à nouveau</button>
            </div>
        </div>
    </div>
</template>

<script>
import routes from '../router'
import axios from 'axios'
export default {
    name: 'DateCalendar',
    data() {
        const date = new Date();
        const monthIndex = date.getMonth();
        const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        return {
            year: date.getFullYear(),
            currentMonthIndex: monthIndex,
            currentMonthName: months[monthIndex],
            weekdays: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'],
            months: months,
            selectedMode: 'day',
            selectedDates: [],
            dateAndHours: {},
            selectedDate: null,
            selectedHour: [],
            showHours: false,
            reservations: [],
            equipe: {},


        };
    },
    mounted() {
        this.fetchEquipeData();
    },
    computed: {
        reservedDates() {
            return this.reservations.map(reservation => reservation.dateReservation);
        },
        daysInMonth() {
            const lastDayOfMonth = new Date(this.year, this.currentMonthIndex + 1, 0).getDate();
            let days = [];
            const firstDayOfMonth = new Date(this.year, this.currentMonthIndex, 1).getDay();
            const startDay = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;
            const prevMonthLastDay = new Date(this.year, this.currentMonthIndex, 0).getDate();
            const startDayPrevMonth = prevMonthLastDay - startDay + 1;

            for (let i = startDayPrevMonth; i <= prevMonthLastDay; i++) {
                days.push({ day: '', month: this.currentMonthIndex - 1, year: this.year });
            }

            for (let i = 1; i <= lastDayOfMonth; i++) {
                days.push({ day: i, month: this.currentMonthIndex, year: this.year });
            }

            const daysNeeded = 35 - days.length;
            for (let i = 1; i <= daysNeeded; i++) {
                days.push({ day: '', month: this.currentMonthIndex + 1, year: this.year });
            }

            return days;
        },
        // isHeureReservationEqual() {
        //     return (heureReservationValue) => {
        //         return this.reservations.some(reservation => reservation.heureReservation === heureReservationValue);
        //     };
        // },

    },

    methods: {
        isHeureReservationEqualForDate(heureReservation, day, month, year) {
            return this.reservations.some(reservation => {
                return (
                    reservation.dateReservation === `${day}/${month}/${year}` &&
                    reservation.heureReservation === heureReservation
                );
            });
        },

        isHeureReservationEqual(hour) {
            const selectedDateStr = this.selectedDate;
            return this.reservations.some(reservation => {
                // Découpez l'heure de la réservation pour comparer les heures de début et de fin
                const [startHour, endHour] = reservation.heureReservation.split(',').map(periode => periode.trim());
                return reservation.dateReservation === selectedDateStr && (hour === startHour || hour === endHour);
            });
        },
        redirectToReserveNouveau() {
            // const routes = useRouter()
            routes.push('/Reservation')
        },
        changeMode() {
            this.selectedDates = [];
            this.selectedHour = [];
            this.showHours = false;
        },
        isWeekend(day) {
            const date = new Date(this.year, this.currentMonthIndex, day);
            const weekday = date.getDay();
            return weekday === 0 || weekday === 6;
        },
        prevMonth() {
            if (this.currentMonthIndex > 0) {
                this.currentMonthIndex--;
            } else {
                this.currentMonthIndex = 11;
                this.year--;
            }
            this.currentMonthName = this.months[this.currentMonthIndex];
        },
        nextMonth() {
            if (this.currentMonthIndex < 11) {
                this.currentMonthIndex++;
            } else {
                this.currentMonthIndex = 0;
                this.year++;
            }
            this.currentMonthName = this.months[this.currentMonthIndex];
        },
        toggleDate(day) {
            // Vérifie si la journée sélectionnée n'est pas vide
            if (day.day !== '') {

                // Construit une chaîne de date sélectionnée au format jour/mois/année
                const selectedDateStr = `${day.day}/${this.currentMonthIndex + 1}/${this.year}`;

                // Vérifie le mode de sélection actuel
                if (this.selectedMode === 'day') {
                    // Si en mode "jour", définir la date sélectionnée et afficher les heures

                    // Réinitialise la liste des dates sélectionnées et des heures
                    this.selectedDates = [selectedDateStr];
                    this.selectedHour = [];
                    this.showHours = true;

                    // Définit la date sélectionnée comme étant la première date de la liste
                    this.selectedDate = this.selectedDates[0];

                } else if (this.selectedMode === 'month') {
                    // Si en mode "mois", ajoute ou supprime la date sélectionnée de la liste des dates

                    // Recherche la position de la date sélectionnée dans la liste des dates
                    const index = this.selectedDates.indexOf(selectedDateStr);

                    // Si la date n'est pas déjà dans la liste, l'ajoute
                    if (index === -1) {
                        // Vérifie si aucune heure n'est sélectionnée pour la date précédente
                        if (this.selectedDates.length > 0 && !this.selectedHour.some(h => h.date === this.selectedDates[this.selectedDates.length - 1])) {
                            // Affiche une alerte pour informer l'utilisateur
                            alert('Veuillez sélectionner au moins une heure pour la date précédente avant de choisir une nouvelle date.');
                            return; // Arrête la fonction ici
                        }

                        this.selectedDates.push(selectedDateStr);
                    } else {
                        // Sinon, la supprime de la liste
                        this.selectedDates.splice(index, 1);
                    }

                    // Affiche les heures pour la dernière date sélectionnée
                    this.showHours = true;
                }
            }
        },

        toggleHour(hour) {
            if (this.isSelectedHour(hour)) {
                this.selectedHour = this.selectedHour.filter(h => h !== hour);
            } else {
                this.selectedHour.push(hour);
            }
        },

        isSelected(day) {
            const dateStr = `${day.day}/${this.currentMonthIndex + 1}/${this.year}`;
            return this.selectedDates.includes(dateStr);
        },
        isSelectedHour(hour) {
            return this.selectedHour.includes(hour);
        },
        availableHoursForSelectedDateWithReservations(dateStr) {
            const day = parseInt(dateStr.split('/')[0]);
            const month = this.currentMonthIndex;
            const year = this.year;
            const dateObj = new Date(year, month, day);
            const weekday = dateObj.getDay();
            let availableHours = [];

            if (weekday === 6 || weekday === 0) {
                availableHours = ['6h - 8h', '8h - 10h', '10h - 12h', '12h - 14h', '14h - 16h', '16h - 18h', '18h - 20h'];
            } else if (weekday >= 1 && weekday <= 5) {
                availableHours = ['6h - 8h', '12h - 14h', '16h - 18h', '18h - 20h'];
            }

            return availableHours.map(hour => ({
                time: hour,
                reserved: this.isHeureReservationEqual(hour),
            }));
        },

        availableHoursForSelectedDate(day) {
            const dateObj = new Date(this.year, this.currentMonthIndex, parseInt(day));
            const weekday = dateObj.getDay();

            // Heures de disponibilité en fonction du jour de la semaine
            let availableHours = [];
            if (weekday === 6) {
                availableHours = ['6h - 8h', '8h - 10h', '10h - 12h', '12h - 14h', '14h - 16h', '16h - 18h', '18h - 20h'];
            } else if (weekday === 0) {
                availableHours = ['6h - 8h', '8h - 10h', '10h - 12h', '12h - 14h', '14h - 16h', '16h - 18h', '18h - 20h'];
            } else if (weekday >= 1 && weekday <= 5) {
                availableHours = ['6h - 8h', '12h - 14h', '16h - 18h', '17h - 19h', '18h - 20h'];
            }

            // Retourne les heures disponibles avec l'état de réservation
            return availableHours.map(hour => ({
                time: hour,
                reserved: this.isHeureReservationEqualForDate(hour, day)
            }));
        },
        async fetchEquipeData() {
            try {
                const response = await axios.get('http://127.0.0.1:8000/api/equipes');
                const data = response.data;
                this.equipe = data.equipes[0];
                this.reservations = data.reservations;
                console.log(this.reservations); // Vérifiez le format des données dans la console
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        },
        getToken() {
            // Récupère le jeton depuis le stockage local
            return localStorage.getItem('token');
        },
        isDateReserved(day) {
            // Construit une chaîne de date au format jour/mois/année pour la journée actuelle
            const currentDayStr = `${day.day}/${this.currentMonthIndex + 1}/${this.year}`;

            // Vérifie si la chaîne de date actuelle est présente dans les dates réservées
            return this.reservedDates.includes(currentDayStr);
        }
        ,
    }
};
</script>
<style lang="css" scoped>
.affichage_heure_reser {
    border: 1px solid #808080;
    width: 60%;
    padding: 1%;
    margin-right: 1%;
    border-radius: 5px;
    text-align: center;
}

.day.reserved-date {
    background-color: #C90119;
    color: white;
}

/* .day.hovered {
    background-color: lightgray;
} */

.reserved-date {
    background-color: red;
    color: white;
}

.heure-reservation-match {
    background-color: #C90119;
    /* Couleur rouge pour les heures de réservation correspondantes */
    color: white;
    /* Ajoutez d'autres styles selon vos besoins */
}

.book {
    width: 8%;
    margin-left: 5%;
}

.h_affiche_g {
    float: left;
    width: 50%;

    box-sizing: border-box;
}

.h_affiche_d {
    float: left;
    width: 50%;
    box-sizing: border-box;
    margin-left: -17%;
    margin-top: 9%;
}

.heure_affiche_reservation {
    width: 180px;
    margin: 3%;
    margin-left: 105px;
    font-size: large;
    font-weight: bold;

}

.cadre_affiche_heure {
    display: flex;
    flex-wrap: wrap
}

.nom_affiche_equipe {
    margin-bottom: 3%;
    margin-left: 105px;
    width: 180px;
    font-size: large;
    font-weight: bold;

}

.affiche_equipe {
    /* margin: 1%; */
    border-radius: 5px;
    border: 1px solid #808080;
    width: 180px;
    margin-left: 37%;
    padding: 1%;
    height: 50px;
    background-color: #D9D9D9;

}

.affiche_heure {
    border: 1px solid #808080;
    margin: 5%;
    padding: 5%;
    width: 60%;
    border-radius: 5px;
    margin-left: 106px;
    text-align: center;
    /* flex: 1; */
    box-sizing: border-box;
}

.text_couleur {
    margin: 0 2% 0 3%;
}

.choix_couleur {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin-bottom: 1%;
}

.p_cadre_I {
    width: 10%;
    height: 25px;
    /* border: 1px solid; */
    background-color: #C90119;
    margin-right: -1%;
}

.p_cadre_D {
    width: 10%;
    height: 25px;
    /* border: 1px solid; */
    background-color: #D9D9D9;
    margin-right: 2%;
}

.btn_src {
    display: flex;
    gap: 10px;
    margin-left: 100%;

}

.btn_suive {
    /* position: fixed;
    top: 0;
    left: 0; */
    width: 150%;
    background-color: #41A62A;
    border: none;
    border-radius: 10px;
    color: white;
    float: left;
    margin-left: 130%;
    /* espace entre les boutons */
    /* padding: 10px 20px; */
    cursor: pointer;
    padding: 10px 20px;
    /* margin: 15%; */
    margin-top: 20%;
}


.Date_Heure {
    display: flex;
    /* border: 1px solid; */
}

.D_H_select {
    /* flex: 1; */
    /* display: flex;
    border: 1px solid;
    width: 70%;
    margin-left: 15%;
    margin-top: -8%;
    padding: 2%; */
    /* background-color: #D9D9D9; */
    /* border: 1px solid #D9D9D9;; */
    float: left;
    width: 50%;
    /* Pour que chaque div prenne la moitié de la largeur */
    box-sizing: border-box;
    margin-top: -6%;
}

.affichage_D_H {
    flex: 1;

}

.titre_d_t_select {
    color: #41A62A;
}

/* Styles spécifiques pour DateCalendar */
.date-calendar {
    margin-left: 5%;
    /* Marge à gauche pour laisser de l'espace pour Fond_Gauche_Reser */
    /* width: 75%; */
    /* 100% - largeur de Fond_Gauche_Reser */
    /* border: 1px solid black; */
}

.hours {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    margin-top: 20px; */
    display: flex;
    flex-direction: column;
    /* Disposition en colonne */
}

.hours h3 {
    margin-bottom: 10px;
}

.btn_cadre {
    display: flex;
    /* Disposition des boutons en ligne */
}

.btn_cadre button {
    margin-right: 10px;
    /* Espacement entre les boutons */
}

.hours button {
    padding: 1%;
    background-color: white;
    border: 1px solid;
    color: black;
    cursor: pointer;
    /* transition: background-color 0.3s ease; */
    margin: 4px 4px 0 5px;
    /* Espacement pour séparer les boutons et du paragraphe */
    flex-grow: 1;
    width: 90px;
}

/* .hours button.selected {
    background-color: #C90119;
    color: white;
} */

.selected-dates {
    margin-top: 20px;
}

.selected-dates ul {
    list-style-type: none;
    padding-left: 0;
}

.selected-dates li {
    margin: 5px 0;
}

.choix {
    border: none;
    margin-left: 30%;
    width: 20%;
    font-weight: bold;
}

.day {
    text-align: center;
    background-color: #D9D9D9;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
}

.day.empty {
    background-color: transparent;
    /* fond transparent */
    color: white;
    /* texte en blanc */
}

.day.hovered {
    /* background-color: #C90119; */
    /* couleur lors du survol */
}

.calendar {
    font-family: Arial, sans-serif;
    width: 70%;
    margin: 20px auto;
    padding: 20px;
    margin-top: 0%;
    /* border: 1px solid black; */
    /* border-radius: 5px; */
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
    /* background-color: white; */
    float: left;
    /* width: 50%; */
    /* Pour que chaque div prenne la moitié de la largeur */
    box-sizing: border-box;
}

.navigation {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin-bottom: 1%;
}

.navigation h2 {
    margin: 0;
    font-size: 1.5em;
    text-align: center;
}

.navigation button {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    color: black;
    transition: color 0.3s ease;
    margin: 0%;
}

.navigation button:hover {
    color: #007bff;
}

.btn_gauche {
    background-color: black;
}

.weekdays {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    /* border-bottom: 1px solid #ccc; */
}

.weekday {
    flex: 0;
    text-align: center;
    font-weight: bold;
    color: #555;
    padding: 10px;
    border: none;
}

.days {
    display: grid;
    grid-template-columns: repeat(7, minmax(83px, 1fr));
    gap: 15px;
    border: none;
}

.day {
    text-align: center;
    background-color: #D9D9D9;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
    border: none;
}

/* Media Query pour les petits écrans */
@media (max-width: 768px) {
    .days {
        grid-template-columns: repeat(7, minmax(70px, 1fr));
        gap: 10px;
    }

    .day {
        padding: 10px;
    }
}

fieldset {
    width: 50%;
    padding: 2%;
    margin: 2%;
    margin-left: 1%;
    border-radius: 5px;
    border: 1px solid #808080;
    color: #41A62A;
}

.titre_date_s {
    color: #41A62A;

}

.titre-kely {
    color: black;
    margin-left: 7%;
}

.titre-kely {
    display: inline-block;
    margin-right: 10px;
    /* Marge à droite pour séparer les éléments */
}

#mode {
    display: inline-block;
}


.day.today {
    color: #fff;
}
</style>
