<template>
    <div class="foot">
        
        <connex_util></connex_util>
        
    </div>
</template>

<script>
import connex_util from './connex_util.vue'




export default {
    components: {
        connex_util
    }

}
</script>


<style scoped>
.foot {
    background-color: white;
    width: 100%;
    /* Utilisation de % pour la largeur */
    min-height: 100vh;
}
</style>
