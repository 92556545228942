<template>
  <div id="app">
    <router-view name="inscri"></router-view>
    <router-view name="Conex"></router-view>
    <router-view name="Page1"></router-view>
    <router-view name="formcli"></router-view>
    <router-view name="Transc"></router-view>
    <router-view name="Gest"></router-view>
    <router-view name="Infrme"></router-view>
    <router-view name="Pop"></router-view>
    <router-view name="Res"></router-view>
    <router-view name="Post"></router-view>
    <router-view name="Pay"></router-view>
    <router-view name="A_reservation"></router-view>
    <router-view name="Liste_equipe"></router-view>
    <router-view name="Inscription_poste"></router-view>
    <router-view name="Connexion_poste"></router-view>
    <router-view name="Connexion_A"></router-view>
    <router-view name="Connexion_C"></router-view>
    <router-view name="A_admin"></router-view>
    <router-view name="A_ListePerso"></router-view>
    <router-view name="A_liste_client"></router-view>
    <router-view name="Inscri_A"></router-view>
    <router-view name="Inscri_C"></router-view>
    <router-view name="Liste_P"></router-view>
    <router-view name="Liste_G"></router-view>
    <router-view name="test_C"></router-view>
    <router-view name="test_P"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
/* Styles here */
</style>
