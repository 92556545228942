<template>
    <div class="fond_arrière_nav">
        <div class="cadre_nav">
            <div class="cadre_nav_search"><input type="search" placeholder="Entrer votre recherche"></div>
              <a id="ajout-utilisateurs" @click="openModal" class="lienAjout">
                            <img class="ajout" src="../../assets/Icon/ajout.svg" alt="">Ajout Utilisateur
                 </a>
             
            <div class="cadre_nav_ico">
               
                <div class="cadre_notif"><img class="notif" src="../../assets/Icon/notification.svg" alt=""></div>
                <div class="cadre_profil"><img class="profil" src="../../assets/Icon/profil.svg" alt="">
                    <p class="nom_profil">Nom d'utilisateur</p>
                </div>
            </div>
        </div>
        <div class="modal" v-if="isModalOpen">

        <div class="modal-content">
          <div class="redirect">
            <!-- Contenu de la pop-up -->
            <h3>Veuillez choisir le choix d'utilisateur  :</h3>
            <p class="sous_t_choix">Ajouter en tant que :</p>
            <div class="cadre_trois_profil">
              <div class="cadre_img" @click="RedirectToAdmin"><img class="ico_profil_emploier"
                  src="../../assets/Icon/profil_admin.svg" alt="">
                <p>Admin</p>
              </div>
              <div class="cadre_img" @click="RedirectToComptable"><img class="ico_profil_emploier"
                  src="../../assets/Icon/profil_compta.svg" alt="">
                <p>Comptable</p>
              </div>
              <div class="cadre_img" @click="RedirectToPoste_de_garde"><img class="ico_profil_emploier"
                  src="../../assets/Icon/profil_poste.svg" alt="">
                <p>Poste de garde</p>
              </div>
            </div>
            <!-- Bouton pour fermer la pop-up -->
            <!-- <button class="pop_fermer" @click="closeModal">Términer</button> -->
          </div>
          <div class="close" @click="closeModal">x</div>
        </div>
      </div>

    </div>
</template>

<script>
import routes from '../router'

export default {
  data() {
    return {
      isModalOpen: false // État de la modale, initialement fermée
    };
  },
  methods: {
    // Fonction pour ouvrir la pop-up
    openModal() {
      this.isModalOpen = true;
      document.body.classList.add('modal-open');
    },
    // Fonction pour fermer la pop-up
    closeModal() {
      this.isModalOpen = false;
      document.body.classList.remove('modal-open');
    },
    RedirectToComptable() {
      routes.push('/Inscri_Comptable')
    },
    RedirectToPoste_de_garde() {
      routes.push('/Inscri_Poste_G')
    },
    RedirectToAdmin() {
      routes.push('/Inscri_Admin')
    },
    redirectToConexCompt() {
      // const routes = useRouter()
      routes.push('/conexion')
    }
  }
}


</script>


<style lang="css" scoped>
/*.fond_arrière_nav {
    background-color: #E7D2DF;
    position: fixed;*/
    /* position: absolute; */
    /* width: 100%;
    padding-top: 6%;
    padding-bottom: 2%;
    margin-top: -5%;
    margin-left: -0.1%;

} */

.notif {
    width: 7%;
    /* Largeur relative */
    margin-left: 55%;
    /* Marge relative */
    margin-top: 2%;
}

.profil {
    width: 10%;
    /* Largeur relative */
    margin-left: 7%;
    /* Marge relative */
}
.ajout{
    width:9%;
     border-radius: 50px;
    background-color: #41A62A;
    padding: 2%;
    border: none;
    text-align: initial;
   
}


.cadre_nav {
    display: flex;
    /* position: fixed; */
    justify-content: space-between;
    padding-top: 0%;
    flex-grow: 1;
    /* Permet au deuxième composant de prendre l'espace restant */
    overflow-y: auto;
    /* Permet le défilement vertical */
    max-height: 100vh;
}

.cadre_nav_search {
    margin-left: 0%;
}

.cadre_nav_ico {
    display: flex;
}

.cadre_notif {
    width: 50%;
    /* Largeur relative */
}

.cadre_profil {
    display: flex;
    align-items: center;
    border: none;
    width: 30%;
    /* Largeur relative */
    border-radius: 10px;
    margin-left: -18%;
    /* Marge relative */
    background-color: white;
}

.profil {
    margin-right: 1.5vw;
    /* Utilisation de vw pour une taille relative à la largeur de la fenêtre */
    height: auto;
    border-radius: 50%;
    border: 1px solid;
    padding: 1%;
}

.nom_profil {
    margin-left: 1vw;
    /* Utilisation de vw pour une taille relative à la largeur de la fenêtre */
    font-size: 1.2em;
    /* Utilisation de em pour une taille relative */
}

input {
    border-radius: 10px;
    border: none;
    text-align: center;
    width: 100%;
    /* Largeur relative */
    padding: 0.5em;
    /* Utilisation de em pour un padding relatif */
}
.modal-open {
  overflow: hidden;
}

.close {
  border-radius: 50%;
  background-color: #D9D9D9;
  width: 10%;
  height: 22%;
  font-size: xx-large;
  text-align: center;
  margin-top: -55%;
  margin-left: 103%;
  font-weight: lighter;
}

.sous_t_choix {
  margin-top: 3%;
}

.cadre_img p {
  margin-left: 3%;
  margin-top: 2%;
}

.cadre_trois_profil {
  margin-top: 5%;
  margin-left: 2%;
}

.cadre_img {
  display: flex;
  align-items: center;
  margin-bottom: 3%;
}

.redirect {
  padding-left: 5%;
}

.ico_profil_emploier {
  width: 5%;
  height: auto;

}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Fond semi-transparent pour l'arrière-plan */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #D9D9D9;
  padding: 10px;
  border-radius: 10px;
  /* display: flex; */
  align-items: center;
  width: 30%;
  height: 30%;
  cursor: pointer;
}



/* Media queries pour les styles responsives */
@media screen and (max-width: 768px) {

    /* Exemple de breakpoint pour un écran de taille moyenne */
    .notif,
    .profil,
    .cadre_profil,
    input {
        width: 100%;
        /* Ajustement de la largeur pour les petits écrans */
        margin-left: 0;
        /* Suppression de la marge à gauche */
    }

    .nom_profil {
        font-size: 1em;
        /* Ajustement de la taille de la police */
    }
}
</style>
