<template>
    <div class="fond-arrière">
        <test_navbar_poste></test_navbar_poste>
        <test_poste_info :id_equipe="id_equipe"></test_poste_info>
    </div>

</template>

<script>

import test_navbar_poste from './test_navbar_poste.vue';
import test_poste_info from './test_poste_info.vue';


export default {
    name: 'Page_Poste_de_garde',
    components: {
        test_navbar_poste,
        test_poste_info,
    },
    props: {
        id_equipe: {
            type: String,
            required: true
        },

    },
    created() {
        console.log("id_equipe dans PagePosteDeGarde : ", this.id_equipe);
    },
}
</script>
<style lang="css" scoped>
.fond-arrière {
    /* background-color: #E7D2DF; */
    position: absolute;
    width: 100%;
    /* Utilisation de % pour la largeur */
    min-height: 150vh;
    /* Utilisation de vh pour la hauteur minimale */
    box-sizing: border-box;
    /* Inclure le padding et la bordure dans la hauteur et la largeur */
    padding: 2%;
    /* Ajouter un padding pour l'espacement */
    /* Vous pouvez ajuster le padding, la marge ou la largeur en fonction de vos besoins */
    /* padding-left: 20%; */
    /* Ajustez cette valeur pour correspondre à la largeur de votre nav_bar_gauche */
    box-sizing: border-box
}

/* Media query pour les styles responsives */
@media screen and (max-width: 768px) {
    .fond-arrière {
        padding: 1%;
        /* Réduire le padding pour les petits écrans */
    }
}
</style>
