<template>
    <div class="bg-primary">
        <nav class="navbar navbar-expand-sm bg fixed-top">
            <div class="container-fluid">
                <a class="navbar-brand" href="javascript:void(0)"><img class="img-fluid taille"
                        src="../../assets/image/A5_-_1-removebg-preview.png" alt="" srcset=""></a>
                <form class="d-flex">
                    <input class="form-control me-2" type="text" placeholder="Search">
                </form>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="mynavbar">
                    <ul class="navbar-nav me-auto">
                        <li class="nav-item "><a class="nav-link"><font-awesome-icon :icon="['fas', 'user']" />Profil</a></li>
                        <div class="dropdown mt-lg-0 mt-3">
                            <button type="button" class="btn drop btn-primary dropdown-toggle"
                                data-bs-toggle="dropdown">
                                Menu
                            </button>
                            <ul class="dropdown-menu">
                                <li @click="redirectToReser"><a class="dropdown-item " href="#"><font-awesome-icon
                                            :icon="['fas', 'calendar']" /> Reservation</a></li>
                                <li ><a class="dropdown-item active" href="#"><font-awesome-icon :icon="['fas', 'user']" />
                                        Liste personnelle</a></li>
                                <li  @click="redirectToTestCli"><a class="dropdown-item" href="#"><font-awesome-icon :icon="['fas', 'user']" />
                                        Liste clients</a></li>
                                <li @click="logout"><a class="dropdown-item" href="#"><font-awesome-icon
                                            :icon="['fas', 'sign-out-alt']" /> Déconnecter</a></li>
                            </ul>
                        </div>
                    </ul>

                </div>
            </div>
        </nav>
    </div>
    <div class="container pt-lg-4 pt-5 mt-lg-5 mt-5">
        
        <button class="btn ajout" data-bs-toggle="modal" data-bs-target="#myModal"><img class="ico_ajout me-2" src="../../assets/Icon/ajout.svg" alt="">Nouvelles ajout personnelle</button>
        <h1>Listes personnelle</h1>
        <div class="row">
            <table class="table">
                <thead>
                    <tr>
                        <th>Nom d'utilisateurs</th>
                        <th>Email</th>
                        <th>Rôle</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in personelle" :key="user.id">
                        <td >{{user.name}}</td>
                        <td>{{user.email}}</td>
                        <td>{{user.typeUtilisateur}}</td>
                        <td>
                            <button class="btn btn-danger" @click="deleteUser(user.id)"><font-awesome-icon :icon="['fas', 'trash']" /></button>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
     <!-- The Modal -->
    <div class="modal fade" id="myModal">
        <div class="modal-dialog">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h5 class="modal-title">Veuillez choisir le choix de connexion :</h5>

                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>

                <!-- Modal body -->
               <div class="modal-body">
                    <div class="d-flex justify-content" @click="RedirectToAdmin">
                        <img class="img-fluid ico-profil ms-5 me-3" src="../../assets/Icon/profil_admin.svg" alt="">
                        <p @click="RedirectToAdmin" data-bs-dismiss="modal">Administrateur</p>
                    </div>
                    <div class="d-flex justify-content">
                        <img class="img-fluid ico-profil ms-5 me-3" src="../../assets/Icon/profil_compta.svg" alt="">
                        <p @click="RedirectToComptable" data-bs-dismiss="modal">Comptable</p>
                    </div>
                    <div class="d-flex justify-content">
                        <img class="img-fluid ico-profil ms-5 me-3" src="../../assets/Icon/profil_poste.svg" alt="">
                        <p @click="RedirectToPoste_de_garde" data-bs-dismiss="modal">Poste de garde</p>
                    </div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>

</template>
<script>
import axios from 'axios';
import routes from '../router'
import { Modal } from 'bootstrap';
import Swal from "sweetalert2";
export default {
     data() {
        return {
            personelle: []
        };
    },
    mounted() {
        this.fetchPersonelle();
    },

    methods : {
        redirectToTestCli () {
            routes.push('/Test_liste_cli')
        },
          redirectToTestP () {
            routes.push('/Test_liste_pers')
        },
            redirectToReser () {
            routes.push('/Admin')
        },
         redirectToConexCompt() {
      this.$router.push('/conexion'); 
    },
    RedirectToComptable() {
      this.$router.push('/Inscri_Comptable');
    },
    RedirectToPoste_de_garde() {
      this.$router.push('/Inscri_Poste_G');
    },
     RedirectToAdmin() {
      // Récupération de l'élément du modal
      const modalElement = document.getElementById('myModal');
      if (modalElement) {
        // Récupération ou création d'une instance du modal
        const modal = Modal.getInstance(modalElement) || new Modal(modalElement);
        modal.hide(); // Ferme le modal
      }

      // Redirection après la fermeture du modal
      setTimeout(() => {
        this.$router.push('/Inscri_Admin'); // Redirection via Vue Router
      }, 300); // Délai pour permettre la fermeture du modal
    },
        async fetchPersonelle() {
            try {
                const response = await axios.get('https://terrain.projetphoenix.net/api/Personelle');
                const data = response.data;
               
                this.personelle = data; // Assurez-vous que la réponse contient les données directement
                console.log(this.personelle); // Vérifiez le format des données dans la console
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        },

async deleteUser(userId) {
    // Afficher une boîte de dialogue de confirmation avec SweetAlert
    const confirmation = await Swal.fire({
        toast: true,
        title: 'Êtes-vous sûr ?',
        text: "Cette action est irréversible!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, supprimer',
        cancelButtonText: 'Annuler',
    });

    // Si l'utilisateur clique sur "Annuler", ne pas continuer
    if (!confirmation.isConfirmed) {
        return;
    }

    try {
        // Effectuer la suppression de l'utilisateur avec l'ID correct
        await axios.delete(`https://terrain.projetphoenix.net/api/DeleteUser/${userId}`);

        // Recharge la liste après suppression
        this.fetchPersonelle();

        // Afficher une alerte de succès avec SweetAlert
        Swal.fire({
            toast: true,
            title: 'Utilisateur supprimé!',
            text: 'L\'utilisateur a été supprimé avec succès.',
            icon: 'success',
            confirmButtonText: 'Ok',
        });
    } catch (error) {
        // Gérer les erreurs lors de la suppression
        console.error('Erreur lors de la suppression de l\'utilisateur :', error);
        
        // Afficher une alerte d'erreur avec SweetAlert
        Swal.fire({
            toast: true,
            title: 'Erreur!',
            text: 'Il y a eu un problème lors de la suppression de l\'utilisateur.',
            icon: 'error',
            confirmButtonText: 'Ok',
        });
    }
},
async logout() {
      const confirmation = await Swal.fire({
        toast:true,
        title: "Êtes-vous sûr de vouloir vous déconnecter ?",
        text: "Vous devrez vous reconnecter pour accéder à votre compte.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Oui",
        cancelButtonText: "Annuler"
      });

      if (confirmation.isConfirmed) {
        try {
          const response = await axios.post("https://terrain.projetphoenix.net/api/logout");

          // Supprimer le token du localStorage
          localStorage.removeItem("curent_token");

          // Afficher une alerte de succès
          Swal.fire({
            toast:true,
            title: "Déconnexion réussie",
            text: response.data.message,
            icon: "success",
            confirmButtonText: "OK"
          }).then(() => {
            // Rediriger vers la page de connexion
            this.$router.push("/");
          });

        } catch (error) {
          console.error("Erreur lors de la déconnexion:", error);
          Swal.fire({
            toast:true,
            title: "Erreur",
            text: "Une erreur est survenue lors de la déconnexion.",
            icon: "error",
            confirmButtonText: "OK"
          });
        }
      }
    },
    }
};

</script>

<style scoped>
.drop {
    width: 100%;
    background-color: #41A62A;
    border: none;
}

.taille {
    width: 10%;
}

.bg {
    background-color: #E7D2DF;
}
.ico_ajout{
    width: 5%;
    height: 5%;
}

.ico {
    width: 5%;
}

.bg_date {
    background-color: #41A62A;
}

.heure {
    background-color: white;
    color: black;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
}

.cadre {
    float: left;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 1%;
    padding-left: 5%;
    width: 300px;
}

.check {
    width: 20px;
    margin-top: -10px;
}
.ajout{
    width: 30%;
    background-color: #41A62A;
    color: white;
}
.t_class {
    color: #41A62A;
}

.inpt {
    outline: none;
    border: none;
}



.envoyer {
    background-color: #41A62A;
    color: white;
    border-radius: 5px;
    border: none;
}
.InpLpers{
    border:none;
}


/* Écrans de taille très petite (mobile) */
@media (max-width: 576px) {
    .sup {
        width: 100%; /* Prend toute la largeur */
    }
}

/* Écrans de taille petite (tablettes en mode portrait) */
@media (min-width: 577px) and (max-width: 768px) {
    .sup {
        width: 60%; /* Prend 50% de la largeur */
    }
}

/* Écrans de taille moyenne (tablettes en mode paysage) */
@media (min-width: 769px) and (max-width: 992px) {
    .sup {
        width: 45%; /* Prend 35% de la largeur */
    }
}

/* Écrans de taille grande (ordinateurs portables et plus) */
@media (min-width: 993px) {
    .sup {
        width: 30%; /* Largeur de 20% par défaut */
    }
}
.ico-profil {
    width: 4%;
    height: 5%;
}

</style>