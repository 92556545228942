<template>
    <div>
        <nav class="navbar navbar-expand-sm bg-navbar fixed-top">
            <div class="container-fluid">
                <!-- Logo -->
                <a class="navbar-brand" href="#"><img class="img-fluid taille"
                        src="../../assets/image/A5_-_1-removebg-preview.png" alt="Logo"></a>

                <!-- Barre de recherche -->
                <form class="d-flex">
                    <input class="form-control me-2" type="text" placeholder="Search">
                </form>

                <!-- Bouton de menu responsive -->
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar"
                    aria-controls="mynavbar" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <!-- Menu de navigation -->
                <div class="collapse navbar-collapse" id="mynavbar">
                    <ul class="navbar-nav me-auto">
                        <li class="nav-item">
                            <a class="nav-link" href="#"><font-awesome-icon :icon="['fas', 'user']" /> Profil</a>
                        </li>

                        <!-- Dropdown -->
                        <li class="nav-item dropdown">
                            <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown">
                                Menu
                            </button>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item active" href="#"><font-awesome-icon
                                            :icon="['fas', 'calendar']" /> Réservation</a></li>
                                <li @click="redirectToTestP "><a class="dropdown-item" href="#"><font-awesome-icon :icon="['fas', 'user']" />
                                        Liste personnelle</a></li>
                                <li @click="redirectToTestCli"><a class="dropdown-item" href="#"><font-awesome-icon :icon="['fas', 'user']" />
                                        Liste clients</a></li>
                                <li @click="logout"><a class="dropdown-item" href="#"><font-awesome-icon
                                            :icon="['fas', 'sign-out-alt']" /> Déconnecter</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>

    <!-- Contenu principal -->
    <div class="container pt-lg-4 pt-5 mt-lg-5 mt-5">
        <h1>Réservation</h1>
        <div class="row">
            <table class="table">
                <thead>
                    <tr>
                        <th>Heure</th>
                        <th>Date</th>
                        <th>Nom équipe</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="reservations in Reservation" :key="reservations.id">
                        <td>{{reservations.heureReservation}}</td>
                        <td>{{reservations.dateReservation}}</td>
                        <td>{{reservations.equipe.nomEquipe}}</td>
                        <td>
                            <button @click=" deleteRéservation(reservations.id)" class="btn btn-danger"><font-awesome-icon :icon="['fas', 'trash']" /></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import routes from '../router'
import axios from 'axios';
import Swal from "sweetalert2";
export default {
    data() {
        return {
             Reservation: []
        };
    },
    mounted() {
        this.fetchEquipeReservation();
    },

    methods : {

     afficherAlerte() {
      Swal.fire({
        toast:true,
        title: "Bonjour !",
        text: "Ceci est une alerte SweetAlert2 dans Vue.js",
        icon: "success",
        confirmButtonText: "OK"
      });
    },
        redirectToTestCli () {
            routes.push('/Test_liste_cli')
        },
          redirectToTestP () {
            routes.push('/Test_liste_pers')
        },
         async fetchEquipeReservation() {
            try {
                const response = await axios.get('https://terrain.projetphoenix.net/api/EquipeReservation');
                const data = response.data;
               
                this.Reservation = data; // Assurez-vous que la réponse contient les données directement
                console.log(this.Reservation); // Vérifiez le format des données dans la console
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        },
  
async deleteRéservation(reservationsID) {
    // Afficher une boîte de dialogue de confirmation avec SweetAlert
    const confirmation = await Swal.fire({
        toast:true,
        title: 'Êtes-vous sûr ?',
        text: "Cette action est irréversible!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, supprimer',
        cancelButtonText: 'Annuler',
    });

    // Si l'utilisateur clique sur "Annuler", ne pas continuer
    if (!confirmation.isConfirmed) {
        return;
    }

    try {
        // Effectuer la suppression de la réservation avec l'ID correct
        await axios.delete(`https://terrain.projetphoenix.net/api/DeleteReserv/${reservationsID}`);

        // Recharge la liste après suppression
        this.fetchEquipeReservation();

        // Afficher une alerte de succès avec SweetAlert
        Swal.fire({
            toast:true,
            title: 'Réservation supprimée!',
            text: 'La réservation a été supprimée avec succès.',
            icon: 'success',
            confirmButtonText: 'Ok',
        });
    } catch (error) {
        // Gérer les erreurs lors de la suppression
        console.error('Erreur lors de la suppression de la Réservation :', error);
        
        // Afficher une alerte d'erreur avec SweetAlert
        Swal.fire({
            toast:true,
            title: 'Erreur!',
            text: 'Il y a eu un problème lors de la suppression de la réservation.',
            icon: 'error',
            confirmButtonText: 'Ok',
        });
    }
},
async logout() {
      const confirmation = await Swal.fire({
        toast:true,
        title: "Êtes-vous sûr de vouloir vous déconnecter ?",
        text: "Vous devrez vous reconnecter pour accéder à votre compte.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Oui",
        cancelButtonText: "Annuler"
      });

      if (confirmation.isConfirmed) {
        try {
          const response = await axios.post("https://terrain.projetphoenix.net/api/logout");

          // Supprimer le token du localStorage
          localStorage.removeItem("curent_token");

          // Afficher une alerte de succès
          Swal.fire({
            toast:true,
            title: "Déconnexion réussie",
            text: response.data.message,
            icon: "success",
            confirmButtonText: "OK"
          }).then(() => {
            // Rediriger vers la page de connexion
            this.$router.push("/");
          });

        } catch (error) {
          console.error("Erreur lors de la déconnexion:", error);
          Swal.fire({
            toast:true,
            title: "Erreur",
            text: "Une erreur est survenue lors de la déconnexion.",
            icon: "error",
            confirmButtonText: "OK"
          });
        }
      }
    },

    }
};

</script>

<style scoped>
/* Navbar */
.bg-navbar {
    background-color: #E7D2DF;
}

/* Logo */
.taille {
    width: 10%;
}

/* Dropdown */
.dropdown-toggle {
    background-color: #41A62A;
    border: none;
}

/* Table */
.table th, .table td {
    text-align: center;
}

/* Bouton Supprimer */
.btn-danger {
    width: auto;
}

/* Responsive */
@media (max-width: 768px) {
    .navbar-nav {
        text-align: center;
    }
}
body {
    background-color: #fff;
}

.container {
    background-color: #fff;
}
.container {
    padding-top: 70px; /* Ajustez selon la hauteur de votre navbar */
}
</style>
