<template>
    <div class="fond-arrière_admin_client">
        <Fond_gauche_admin></Fond_gauche_admin>
        <Nav_bar_haut></Nav_bar_haut>
        <liste_client></liste_client>
    </div>
</template>

<script>

import Fond_gauche_admin from './Fond_gauche_admin.vue'
import Nav_bar_haut from '../Poste_de_garde/Nav_bar_haut.vue';
import liste_client from './liste_client.vue'
export default {
    components: {
        Fond_gauche_admin,
        Nav_bar_haut,
        liste_client
    }
}
</script>

<style lang="css" scoped>

</style>