<template>
    <nav class="text-white navbar navbar-expand-lg navbar-light ">
        <div class="container-fluid bg-nav fixed-top d-flex justify-content-end ">
            <div class="row ">
                <div class="col-12 ">
                    <div class="collapse navbar-collapse " id="navbarNav">
                        <ul class="navbar-nav ">
                            <li class="nav-item">
                                <a class="text-white nav-link espace-a" aria-current="page" href="#">Home</a>
                            </li>
                            <li class="nav-item">
                                <a class="text-white nav-link espace-b" href="#">Contact</a>
                            </li>
                            <li><img class="img-fluid profil_acceuil" src="../../assets/Icon/profil_nav_accueille.svg"
                                    alt="" data-bs-toggle="modal" data-bs-target="#myModal"></li>
                        </ul>
                    </div>
                </div>
            </div>
            <a class="text-white navbar-brand" href="#"></a>
            <button class="navbar-toggler bg_white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>

    </nav>
    <div class="container-fluid espace">
        <div class="text-center text-white row bg-img d-flex justify-content-center">
            <h3 class="petit-titre">Jouer avec <span class="nous">nous</span></h3>
            <button class="btn reserve d-flex justify-content-center"  @click ="redirectToConexCompt">Réserver</button>
        </div>

    </div>
    <div class="container espace-d">
        <div class="row">
            <div class="col-12 col-lg-4 " data-aos="fade-right">
                <h1>Droit de réservation sur le terrain de commission sport</h1>
            </div>
            <div class="mb-2 col-12 col-lg-4" data-aos="fade-up">
                <div class="p-5 bordure ">
                    <img class="img-fluid p-ico d-none d-lg-block" src="../../assets/image/glos-removebg-preview.png"
                        alt="">

                    <h4>Types de réservation</h4>
                    <ul class="lts">
                        <li>Par JOUR</li>
                        <li>Par SEMAINE</li>
                        <li>Par MOIS</li>
                    </ul>
                </div>
            </div>
            <div class="mb-2 col-12 col-lg-4" data-aos="fade-left">
                <div class="p-5 bordure">
                    <img class="img-fluid p-ico1 d-none d-lg-block" src="../../assets/image/tarif-removebg-preview.png"
                        alt="">
                    <p>Tarifs de réservation</p>
                    <p class="mt-4 para">2 heures : 15 000 ar</p>
                </div>

            </div>
        </div>
    </div>
    <div class="container-fluid bg-img1" data-aos="fade-up">
        <div class="text-white row bg_dessus ">
            <div class="col-12 bg_color_red mt-lg-5">
                <h3 class="mt-4 mb-5 text-center">Heures de réservation</h3>
                <div class="gauche ms-lg-5">
                    <ul>
                        <li class="mb-2 point">Lundi à Vendredi</li>
                        <li class="mb-2 point">&nbsp;</li>
                        <li class="mb-2 point">&nbsp;</li>
                        <li class="mb-2 point">Samedi</li>
                        <li class="mb-2 point">Dimanche</li>
                    </ul>
                </div>
                <div class="mt-1 droite mt-lg-1 ms-lg-5">
                    <ul>
                        <li class="mb-2 point">7H - 9H</li>
                        <li class="mb-2 point">12H - 14H</li>
                        <li class="mb-2 point">18H - 20H</li>
                        <li class="mb-2 point">8H - 20H</li>
                        <li class="mb-2 point">7H - 20H</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container mt-5 mb-5">
        <div class="row">
            <div class="col-12" data-aos="fade-up">
                <h1 class="text-center " data-aos="fade-up">Bonjour, pour faciliter votre réservation</h1>
                <h1 class="mb-3 text-center" data-aos="fade-up">Nos Conditions</h1>
                <ul class="text-center point">
                    <li data-aos="fade-up">Le nombre maximum de personne ayant accès au terrain est limité à 20 (joueurs et spectateurs)
                    </li>
                    <li data-aos="fade-up">Le payement de cette contribution doit se faire avant l'utilisation de terrain.</li>
                    <li data-aos="fade-up">Une annulation de réservation par l'utilisateur n'est pas remboursable, par contre, celle faite
                        par
                        les Commission Sport l'est.</li>
                    <li data-aos="fade-up">Chaque equipe doit apporter ses propres matériels de sport.</li>
                    <li data-aos="fade-up">Une réservation de long durée doit être renouvelée tous les 3 mois.</li>
                    <li data-aos="fade-up">Toute tentative de vol ou infraction sera traduite devant les autorités compétentes</li>
                    <li data-aos="fade-up">Les gros mots, injures et alteractions sont formellement interdites dans l'enceinte du terrain.
                    </li>
                    <li data-aos="fade-up">Il est défendu de se surpendre au cerceau (dunker)</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="text-center row bg_pied">
            <div class="p-3 col-12">
                <p>&copy; 2024 Commission Sport. Tous droits réservés.</p>
            </div>
        </div>
    </div>
    <!-- The Modal -->
    <div class="modal fade" id="myModal">
        <div class="modal-dialog">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h5 class="modal-title">Veuillez choisir le choix de connexion :</h5>

                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>

                <!-- Modal body -->
               <div class="modal-body">
                    <div class="d-flex justify-content" @click="RedirectToAdmin">
                        <img class="img-fluid ico-profil ms-5 me-3" src="../../assets/Icon/profil_admin.svg" alt="">
                        <p @click="RedirectToAdmin" data-bs-dismiss="modal">Administrateur</p>
                    </div>
                    <div class="d-flex justify-content">
                        <img class="img-fluid ico-profil ms-5 me-3" src="../../assets/Icon/profil_compta.svg" alt="">
                        <p @click="RedirectToComptable" data-bs-dismiss="modal">Comptable</p>
                    </div>
                    <div class="d-flex justify-content">
                        <img class="img-fluid ico-profil ms-5 me-3" src="../../assets/Icon/profil_poste.svg" alt="">
                        <p @click="RedirectToPoste_de_garde" data-bs-dismiss="modal">Poste de garde</p>
                    </div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>


</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import { Modal } from 'bootstrap';

export default {
  name: 'ResponsiveNavbar',
  methods: {
    redirectToConexCompt() {
      this.$router.push('/conexion'); // Utilisation correcte de Vue Router
    },
    RedirectToComptable() {
      this.$router.push('/Connex_Comptable');
    },
    RedirectToPoste_de_garde() {
      this.$router.push('/Connex_Poste_G');
    },
    RedirectToAdmin() {
      // Récupération de l'élément du modal
      const modalElement = document.getElementById('myModal');
      if (modalElement) {
        // Récupération ou création d'une instance du modal
        const modal = Modal.getInstance(modalElement) || new Modal(modalElement);
        modal.hide(); // Ferme le modal
      }

      // Redirection après la fermeture du modal
      setTimeout(() => {
        this.$router.push('/Connex_Administrateur'); // Redirection via Vue Router
      }, 300); // Délai pour permettre la fermeture du modal
    },
  },
};
</script>

<style scoped>
/* Ajoute des styles supplémentaires si nécessaire */
.bg-nav {
    background-color: rgba(51, 51, 51, 0.65);
    /* position: absolute; */
}

.espace {
    margin-top: -45px;
}

/* .espace-a {
    margin-left: 1100px;
}

.espace-b {
    margin-left: 30px;
} */

.profil_acceuil {
    width: 7%;
    border: 1px solid white;
    border-radius: 50%;
    /* margin-left: 20px; */
    margin-top: 10px;
}

.petit-titre {
    margin-top: 250px;
}

.bordure {
    border: 1px solid black;
    border-radius: 5px;
}

.p-ico {
    position: absolute;
    width: 8%;
    margin-left: 0px;
    margin-top: -100px;
}

.p-ico1 {
    position: absolute;
    width: 9%;
    margin-left: 0px;
    margin-top: -100px;
}

.reserve {
    width: 100%;
}

.espace-d {
    margin-top: 7%;
    margin-bottom: 7%;
}

.para {
    margin-bottom: 63px;
}

.nous {
    color: #C80018;
}

.bg-img {
    background-image: url("../../assets/image/background.jpg");
    height: 450px;
}

.bg-img1 {
    background-image: url("../../assets/image/Rectangle\ 24.jpg");
    height: 400px;
    background-size: cover;
}

.bg_dessus {
    background-color: rgba(51, 51, 51, 0.65);
    height: 400px;
}

.bg_color_red {
    background-color: #C80018;
    height: 300px;
    width: 500px;
}

.gauche {
    float: left;
}


.droite {
    /* margin-left: 100px; */
    float: left;
    color: black;
}

.point {
    list-style: none;
}

.liste {
    list-style: none;
}

.bg_pied {
    background-color: #f4f4f4;
}

.btn {
    background-color: #C80018;
    color: white;
    width: 20%;
    height: 10%;
}

.ico-profil {
    width: 4%;
    height: 5%;
}
</style>
