<template>
    <div class="container ">
        <div class="row mt-5 mt-lg-5 pt-lg-3">
            <div class="col-12 col-lg-2">
                <a class="btn" href="" data-bs-toggle="modal" data-bs-target="#myModal">Nouvelle motif</a>
            </div>
        </div>
        <div class="row mt-4">
            <table class=" table table-hover">
                <thead class="">
                    <h3>Transaction client</h3>
                    <tr>
                        <th class="color-text tableau">Date</th>
                        <th class="tableau">Motif</th>
                        <th class="tableau">Nom Responsable</th>
                        <th class="tableau">N° télephone</th>
                        <th class="tableau">Crédit</th>
                        <th class="tableau">Debit</th>
                        <th class="tableau">Solde</th>
                    </tr>
                </thead>
                <tbody class="">
                    <tr v-for="reservation in reservations" :key="reservation.id">
                        <td class="tableau">{{ reservation.dateReservation }}</td>
                        <td class="tableau">réservation</td>
                        <td class="tableau">{{ reservation.user?.responsable?.nomResponsable || 'N/A' }}</td>
                        <td class="tableau">{{ reservation.user?.responsable?.telResponsable || 'N/A' }}</td>
                        <td class="tableau">{{ reservation.montanApayer }}</td>
                        <td class="tableau"></td>
                        <td class="tableau"></td>
                    </tr>
                </tbody>
            </table>
            
        </div>
        <div class="modal" id="myModal">
            <div class="modal-dialog modal-sm">
                <div class="modal-content">

                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Veuillez remplir le motif</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>

                    <!-- Modal body -->
                    <div class="modal-body">
                        <form action="" method="post">

                            <div class="form-floating mb-3 mt-3">
                                <input class="form-control" type="date" id="date" name="text">
                                <label for="date">Motif</label>
                            </div>
                            <div class="form-floating mb-3 mt-3">
                                <input class="form-control" type="text" id="motif" name="text" placeholder="Motif">
                                <label for="motif">Motif</label>
                            </div>
                            <div class="form-floating mb-3 mt-3">
                                <input class="form-control" type="text" id="responsable" name="text"
                                    placeholder="Nom responsable">
                                <label for="responsable">Nom responsable</label>
                            </div>
                            <div class="form-floating mb-3 mt-3">
                                <input class="form-control" type="text" id="tel" name="text"
                                    placeholder="Numéro telephone">
                                <label for="tel">N° télephone</label>
                            </div>
                            <div class="form-floating mb-3 mt-3">
                                <input class="form-control" type="text" id="credit" name="text" placeholder="crédit">
                                <label for="credit">Crédit</label>
                            </div>
                            <div class="form-floating mb-3 mt-3">
                                <input class="form-control" type="text" id="debit" name="text" placeholder="débit">
                                <label for="debit">Dédit</label>
                            </div>
                            <input class="btn" type="submit" value="Ajouter">
                        </form>
                    </div>

                    <!-- Modal footer -->
                    <div class="modal-footer">

                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import axios from 'axios'
export default {

  data(){
     return{
           reservations: [],
         
          }
  },
    mounted() {
        axios.get('http://127.0.0.1:8000/api/ResponsableReservation')
      .then(response => {
        
        this.reservations = response.data;
        console.log('Reservations:', this.reservations); // Log reservations data
      })
      .catch(error => {
        console.error('Error fetching reservations:', error);
      });
  },
   
  

}
</script>
<style scoped>
.btn {
    background-color: #41A62A;
    color: white;
}

.tableau {
    /* display: flex;
    flex-direction: column; */
    border: 1px solid#D9D9D9;
}
</style>