<template>
    <div class="container-fluid">
         <form @submit.prevent="save">
        <div class="row">
            <div class="col-12 col-lg-5 p-3 text-center d-none d-lg-block fixed-top">
                <div class="bordur p-3">
                    <img class="img-fluid logo" src="../../assets/image/A5_-_1-removebg-preview.png" alt="">
                    <h1 class="mb-4">Formulaire</h1>
                    <div class="rond text-center pt-2 mb-2">1</div>
                    <p class="t_etape">Details clients</p>
                    <div class="ligne mb-4"></div>
                    <div class="rond1 text-center pt-2 mb-2">2</div>
                    <p class="t_etape">Réservation</p>
                    <div class="ligne1 mb-4 "></div>
                    <div class="rond1 text-center pt-2 mb-2">3</div>
                    <p class="t_etape">Payement</p>
                </div>
            </div>
            <div class="col-12 col-lg-5">

            </div>
          
            <div class="col-12 col-lg-6 ms-1 ms-lg-5 pb-3 hozizon">
                
                    <h1 class="m-3">Vos informations</h1>
                    <div class="cadre me-5 me-sm-5 mb-3 mb-sm-2">
                        <h5 class="t_class">Responsable</h5>
                        <input required class="inpt" type="text" name="" id="" v-model="Equipe.nomResponsable"  placeholder="Entrer le responsable">
                    </div>
                    <div class="cadre1 mb-3 ms-lg-5">
                        <h5 class="t_class">Equipe</h5>
                        <input required class="inpt" type="text" name="" id="" v-model="Equipe.telResponsable"  placeholder="Entrer votre equipe">
                    </div>
                    <div class="cadre me-5 me-lg-2=5 mb-3">
                        <h5 class="t_class">Télophone</h5>
                        <input required class="inpt" type="text" name="" id="" v-model="Equipe.nomEquipe" placeholder="Entrer votre n° télephone">
                    </div>
                    <div class="cadre1 mb-3 ms-lg-5">
                        <h5 class="t_class">E_mail</h5>
                        <input required class="inpt" type="email" name="" id="" v-model="Equipe.emailResponsable" placeholder="Entrer votre email">
                    </div>
               
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-5">
            </div>
            <div class="col-12 col-lg-6 ms-1 ms-lg-5">
                
                    <div class="cadre3">
                        <div class="g_d">
                            <div class="gauche">
                                <h4 class="text center t_class">Nom des joueurs</h4>
                                <input required class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[0]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input required class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[1]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[2]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[3]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[4]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[5]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[6]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[7]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[8]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[9]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[10]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[11]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[12]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[13]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[14]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[15]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[16]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[17]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[18]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[19]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[20]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[21]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[22]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[23]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[24]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[25]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[26]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[27]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[28]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[29]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[30]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[31]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[32]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[33]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[34]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[35]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[36]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[37]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[38]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[39]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[40]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[41]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[42]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[43]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[44]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[45]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[46]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[47]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[48]" name="" id=""
                                    placeholder="Entrer le nom de joueur">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" v-model="Equipe.nomJoueurs[49]" name="" id=""
                                    placeholder="Entrer le nom de joueur">    
                            </div>
                            <div class="droite">
                                <h4 class="text center t_class">CIN</h4>
                                <input  class="inpstyle text-center mb-2 form-control form-control" type="text" name=""
                                    id=""  v-model="Equipe.cinJoueurs[0]" pLaceholder="CIN"  >
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[1]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[2]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[3]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[4]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[5]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[6]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[7]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[8]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[9]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[10]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[11]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[12]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[13]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[14]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[15]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[16]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[17]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[18]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[19]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[20]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[21]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[22]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[23]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[24]"
                                    pLaceholder="CIN"> 
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[25]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[26]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[27]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[28]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[29]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[30]"
                                    pLaceholder="CIN"> 
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[31]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[32]"
                                    pLaceholder="CIN"> 
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[33]"
                                    pLaceholder="CIN"> 
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[34]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[35]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[36]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[37]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[38]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[39]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[40]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[41]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[42]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[43]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[44]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[45]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[46]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[47]"
                                    pLaceholder="CIN">
                                   <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[48]"
                                    pLaceholder="CIN">
                                <input  class="inpstyle text-center mb-2 form-control" type="text" name="" id="" v-model="Equipe.cinJoueurs[49]"
                                    pLaceholder="CIN">    

                            </div>
                          
                        </div>
                    
                     
                    </div>
            
                
                <button class="btn mt-lg-4 mt-3 mb-4" type="submit">Enregistrer</button>
             
            </div>
      
        
        </div>
    </form>
         
    </div>
      

</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import axios from 'axios';
import routes from '../router'
export default {
    name: 'insertEquipe',
    data() {
        return {
            result: [],
            Equipe: {
                nomResponsable: '',
                telResponsable: '',
                emailResponsable: '',
                nomEquipe: '',
                nomJoueurs: [],
                cinJoueurs: []
            },
            formValid: false
        };
    },
    created() {
        this.loadequipeData();
    },
    methods: {
        loadequipeData() {
            const page = "http://127.0.0.1:8000/api/equipesB";
            axios.get(page)
                .then(response => {
                    this.result = response.data;
                })
                .catch(error => {
                    console.error('Error loading equipe data:', error);
                });
        },
        save() {
            if (this.Equipe.nomResponsable && this.Equipe.telResponsable && this.Equipe.nomEquipe && this.Equipe.emailResponsable && this.Equipe.nomJoueurs && this.Equipe.cinJoueurs) {
                this.formValid = true;
            }
            else {
                alert(" Veuiller Remplir tout les champs");
            }
            axios.post("http://127.0.0.1:8000/api/equipes", this.Equipe)
                .then(response => {

                    this.loadequipeData();
                    this.Equipe = { nomResponsable: '', telResponsable: '', emailResponsable: '', nomEquipe: '', nomJoueurs: [], cinJoueurs: [] }; // Correction ici
                    this.result = response.data;
                    if (response.status === 201) {

                        // Redirection vers une autre page Vue 

                    }
                    alert("le information sont sauvegarder avec succees");
                    routes.push('/Reservation'); // Correction ici

                })
                .catch(error => {
                    console.error('Error saving Client:', error);
                });

        },
        // redirectPagePrecedant() {
        //     routes.push('/Reservation');
        // }







    }
}


</script>

<style scoped>
.logo {
    width: 20%;
    padding-top: 0px;
}

.bordur {
    /* border: 1px solid black; */
    background-color: #C6E4BF;
    border-radius: 10px;
    /* position: fixed; */
    height: 96vh;
}

.rond {
    background-color: #41A62A;
    color: white;
    border-radius: 50%;
    /* padding: 5%; */
    width: 40px;
    height: 40px;
    margin-left: 260px;
}

.rond1 {
    background-color: white;
    color: black;
    border-radius: 50%;
    /* padding: 5%; */
    width: 40px;
    height: 40px;
    margin-left: 260px;

}

.ligne {
    width: 5px;
    height: 75px;
    background-color: #41A62A;
    margin-left: 278px;
}

.ligne1 {
    width: 5px;
    height: 75px;
    background-color: white;
    margin-left: 278px;
}

.t_etape {
    font-weight: bold;
    margin-left: -20px;;
}

.cadre {
    float: left;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 1%;
    padding-left: 5%;
    width: 300px;
}

.cadre3 {
    border: 1px solid #D9D9D9;

    padding: 2%;
    max-height: 300px;
    overflow-y: auto;
    border-radius: 10px;
    /* align-items: center;
    display: flex; */

}

.cadre1 {
    float: left;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 1%;
    padding-left: 5%;
    width: 300px;

}

input {
    border: none;
    outline: none;
}

.t_class {
    color: #41A62A;
}


.g_d {
    display: flex;

}

.gauche,
.droite {
    flex: 1;
    /* margin-top: -130px; */

}

/* .gauche {
    margin-left: 80px;
} */

.inpstyle {
    outline: none;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid #C6E4BF;
    border-radius: 5px;
}

.btn {
    color: white;
    background-color: #41A62A;
    width: 120px;
    /* align-items: flex-end; */
    /* margin-left: 280px; */
}


</style>