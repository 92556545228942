<template>


<nav class="navbar navbar-expand-sm bg-navbar fixed-top">
            <div class="container-fluid">
                <!-- Logo -->
                <a class="navbar-brand" href="#"><img class="img-fluid taille"
                        src="../../assets/image/A5_-_1-removebg-preview.png" alt="Logo"></a>

                <!-- Barre de recherche -->
                <form class="d-flex">
                    <input class="form-control me-2" type="text" placeholder="Search">
                </form>

                <!-- Bouton de menu responsive -->
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar"
                    aria-controls="mynavbar" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <!-- Menu de navigation -->
                <div class="collapse navbar-collapse" id="mynavbar">
                    <ul class="navbar-nav me-auto">
                        <li class="nav-item">
                            <a class="nav-link" href="#"><font-awesome-icon :icon="['fas', 'user']" /> Profil</a>
                        </li>

                        <!-- Dropdown -->
                        <li class="nav-item dropdown">
                            <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown">
                                Menu
                            </button>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item active" href="#"><font-awesome-icon
                                            :icon="['fas', 'calendar']" /> Réservation</a></li>
                                <li @click="redirectToTestP "><a class="dropdown-item" href="#"><font-awesome-icon :icon="['fas', 'user']" />
                                        Liste personnelle</a></li>
                                <li @click="redirectToTestCli"><a class="dropdown-item" href="#"><font-awesome-icon :icon="['fas', 'user']" />
                                        Liste clients</a></li>
                                <li @click="logout"><a class="dropdown-item" href="#"><font-awesome-icon
                                            :icon="['fas', 'sign-out-alt']" /> Déconnecter</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
export default {

}
</script>

<style scoped>
.ico_nav {
    width: 15px;
    height: 15px;
}

.search {
    height: 20px;
    width: 20px;
}

.taille {
    width: 10%;
}

.photo_pro {
    width: 20px;
    height: 20px;
    border: 1px solid;
    padding: 2px;
    border-radius: 50%;
}

.bg {
    background-color: #E7D2DF;

}

.btn_search {
    background-color: #41A62A;
}

.pro {
    width: 150px;
    height: 40px;
    border-radius: 10px;
    margin-top: -10px;
}
.dropdown-menu {
    min-width: 200px; /* Largeur minimale du menu */
}

.dropdown-menu .dropdown-menu {
    left: 100%;
    top: 0;
    margin-left: 0.5rem;
    display: none; /* Cache le sous-menu par défaut */
}

.dropdown-menu .dropdown-item:hover + .dropdown-menu,
.dropdown-menu .dropdown-menu:hover {
    display: block; /* Affiche le sous-menu au survol */
}
.bg-navbar {
    background-color: #E7D2DF;
}

/* Logo */
.taille {
    width: 10%;
}

/* Dropdown */
.dropdown-toggle {
    background-color: #41A62A;
    border: none;
}

/* Table */
.table th, .table td {
    text-align: center;
}

/* Bouton Supprimer */
.btn-danger {
    width: auto;
}

/* Responsive */
@media (max-width: 768px) {
    .navbar-nav {
        text-align: center;
    }
}
body {
    background-color: #fff;
}

.container {
    background-color: #fff;
}
.container {
    padding-top: 70px; /* Ajustez selon la hauteur de votre navbar */
}

</style>