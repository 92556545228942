<template>
    <div id="app">
        <acceuil-test3-vue></acceuil-test3-vue>
        

    </div>

</template>
<script>


import acceuilTest3Vue from './acceuil-test3.vue';

export default {
    components : {
    
    acceuilTest3Vue
   
},
}

</script>
<style scoped>

</style>