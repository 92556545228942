<template>
    <div>
        <test_navbar_poste></test_navbar_poste>
        <test_info_poste></test_info_poste>
    </div>

</template>

<script>

import test_info_poste from './test_info_poste.vue';
import test_navbar_poste from './test_navbar_poste.vue';
export default {
    components: {
        test_navbar_poste,
        test_info_poste,
    }
}
</script>

<style scoped>
.fond_affiche_liste,
.Calendrier_sans_selectionffiche_liste {
    background-color: #E7D2DF;
    position: absolute;
    width: 100%;
    /* Utilisation de % pour la largeur */
    min-height: 150vh;
    /* Utilisation de vh pour la hauteur minimale */
    box-sizing: border-box;
    /* Inclure le padding et la bordure dans la hauteur et la largeur */
    padding: 2%;
    /* Ajouter un padding pour l'espacement */
    /* Vous pouvez ajuster le padding, la marge ou la largeur en fonction de vos besoins */
    /* padding-left: 20%; */
    /* Ajustez cette valeur pour correspondre à la largeur de votre nav_bar_gauche */
    box-sizing: border-box
}

/* Media query pour les styles responsives */
@media screen and (max-width: 768px) {
    .fond_affiche_liste {
        padding: 1%;
        /* Réduire le padding pour les petits écrans */
    }
}
</style>