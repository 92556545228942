<template>
    <div id="app">
        <incription_test></incription_test>
         

    </div>

</template>
<script>

import incription_test from './incription_test.vue'


export default {
    components : {
    
    incription_test
    
},
}
</script>