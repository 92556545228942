<template>
    <div class="container">
        
        <reservation_cli></reservation_cli>
    </div>
</template>

<script>

    
    import reservation_cli from "./reservation_cli.vue";
    export default{
        components:{
            
            reservation_cli,
        }
    }
</script>

<style lang="css" scoped>
.container {
  display: flex;
  align-items: flex-start; /* Aligner les composants en haut */
}
</style>