<template>

    <div class="container pt-5 mt-5 mt-lg-5 p-lg-2 pt-lg-4">
        <div class="mt-1 row">
            <h3>Informations sur l'equipe</h3>
            <div class="col-12 col-lg-9">
                <h4 class="mois_année">{{ currentMonthName }} {{ year }}</h4>
                <div>
                    <table class=" table table-hover">
                        <thead class="">
                            <tr>
                                <th class="color-text tableau">Listes des equipe (lisitr'ireo ekipa)</th>
                            </tr>
                        </thead>
                        <tbody class="">
                            <tr v-for="equipe in equipes" :key="equipe.id">
                                <td class="tableau" @click="selectEquipe(equipe.id)">
                                    
                                    {{ equipe.nomEquipe }}
                              
                            
                                </td>
                                
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12 col-lg-3 ">
                <Calendrier_sans_selection class="d-none d-lg-block"></Calendrier_sans_selection>
                <h3>Réservation aujourd'hui</h3>
                <p class="d__t_courant">{{ currentDate }}</p>
                <div v-if="equipesToday.length === 0">
                    <p>Aucune équipe n'a de réservation pour aujourd'hui.</p>
                    <p>(Tsy misy ekipa nanao famandrihana toerana androany)</p>
                </div>
                <div v-else>
                    <div class="cadre_listeEquipe" v-for="equipe in equipesToday" :key="equipe.id"
                        @click="selectEquipe(equipe.id)">
                        <img class="ico_books" src="../../assets/Icon/book.svg" alt="Icone livre">
                        <div>
                            <p class="n_equipe">{{ equipe.nomEquipe }}</p>
                        </div>
                        <p class="h-selection">
                            Heure(s) sélectionnée(s):
                            <span class="heure-reservation" v-for="(heure, idx) in equipe.heureReservation.split(', ')"
                                :key="idx">
                                {{ heure }}
                                <span v-if="idx !== equipe.heureReservation.split(', ').length - 1">, </span>
                            </span>

                        </p>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import Calendrier_sans_selection from '../Poste_de_garde/Calendrier_sans_selection.vue';
import axios from 'axios';
export default {
    props: {
        id_equipe: {
            type: String,  // Utilisez String pour les bigint dans les props
            required: true
        }
    },
    data() {
        const date = new Date();
        const monthIndex = date.getMonth();
        const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        return {
            currentDate: this.getCurrentDate(),
            year: date.getFullYear(),
            currentMonthIndex: monthIndex,
            currentMonthName: months[monthIndex],
            reservations: [],
            equipes: [],
            equipesToday: [],
        };
    },
    mounted() {
        this.fetchEquipeData();
        this.fetchEquipesByCurrentMonth();
        this.fetchEquipesToday();
    },
    components: {
        Calendrier_sans_selection,
    },
    methods: {
        selectEquipe(id_equipe) {
            console.log('ID d\'équipe sélectionnée :', id_equipe);
            if (id_equipe) {
                this.$router.push({ name: 'Post', params: { id_equipe: id_equipe } });
            } else {
                console.error("ID d'équipe non défini lors de la navigation.");
            }
        },

        formatHeure(heure) {
            // Formatage de l'heure ici, par exemple '14h - 16h'
            return `${heure.heureDebut}h - ${heure.heureFin}h`;
        },
        getCurrentDate() {
            const date = new Date();
            return this.formatDate(date);
        },
        formatDate(date) {
            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'long' });
            const year = date.getFullYear();
            return `${day} ${month} ${year}`;
        },
        async fetchEquipesToday() {
            try {
                const response = await axios.get('http://localhost:8000/api/equipes/filter/current-date');
                this.equipesToday = response.data;
                console.log('Données des équipes aujourd\'hui :', this.equipesToday); // Vérifiez ici les données reçues
            } catch (error) {
                console.error('Erreur lors de la récupération des équipes pour aujourd\'hui :', error);
            }
        },


        async fetchEquipesByCurrentMonth() {
            try {
                const response = await axios.get('http://localhost:8000/api/equipes/filter/current-month');
                this.equipes = response.data;
            } catch (error) {
                console.error('Erreur lors de la récupération des équipes filtrées :', error);
            }
        },
        async fetchEquipeData() {
            try {
                const response = await axios.get('http://127.0.0.1:8000/api/equipesB');
                const data = response.data;
                this.equipes = data.equipes;
                this.reservations = data.reservations;

                console.log("Données brutes de réservation:", this.reservations);
                console.log("Equipes filtrées pour le mois actuel:", this.filterEquipesByCurrentMonth());

            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        },
        parseCustomDate(dateString) {
            const parts = dateString.split('/');
            return new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
        },
        filterEquipesByCurrentMonth() {
            const currentMonth = this.currentMonthIndex;
            const currentYear = this.year;
            let equipeNames = new Set();

            this.reservations.forEach(reservation => {
                const reservationDate = this.parseCustomDate(reservation.dateReservation);
                console.log("Traitement de la réservation:", reservation);
                if (
                    reservationDate.getMonth() === currentMonth &&
                    reservationDate.getFullYear() === currentYear
                ) {
                    const equipe = this.equipes.find(equipe => equipe.id === reservation.equipe_id);
                    if (equipe) {
                        equipeNames.add(equipe.nomEquipe);
                        console.log("Nom d'équipe associé à la réservation:", equipe.nomEquipe);
                    } else {
                        console.log("Aucune équipe trouvée pour la réservation avec l'ID d'équipe:", reservation.id_equipe);
                    }
                }
            });

            return Array.from(equipeNames);
        }
        ,
    }
}
</script>

<style scoped>
.tableau {
    display: flex;
    flex-direction: column;
    border: 1px solid#D9D9D9;
}

.mois_année {
    font-weight: lighter;
    font-size: large;
}

.color-text {
    color: #41A62A;
}

.taille {
    width: 10%;
}

.borduretable {
    border: 1px solid #D9D9D9;
}

.bg {
    background-color: #E7D2DF;
}

#bor {
    /* display: flex; */
    border: 1px solid #D9D9D9;
}

.ico {
    width: 5%;
}

.bg_date {
    background-color: #41A62A;
}



.heure {
    background-color: white;
    color: black;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
}

.cadre {
    float: left;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 1%;
    padding-left: 5%;
    width: 300px;
}

.check {
    width: 20px;
    margin-top: -10px;
}

.t_class {
    color: #41A62A;
}

.inpt {
    outline: none;
    border: none;
}

.envoyer {
    background-color: #41A62A;
    color: white;
    border-radius: 5px;
    border: none;
}
.nom_equipe_semaine {
    border: 1px solid #D9D9D9;
    border-top: none;
    border-left: none;
    border-right: none;
    color: #808080;
    padding-left: 10%;
    padding-bottom: 5%;
    padding-top: 5%;
}
.ico_books {
    width: 20px;
    height: 20px;
}
</style>