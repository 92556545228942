<template>
    <div class="fond-arrière_admin">
      
        <Test_AdminVue></Test_AdminVue>
    </div>
</template>

<script>



import Test_AdminVue from './Test_Admin.vue';
export default {
    components: {
        Test_AdminVue
    },
    mounted() {
        document.title = "Nouveau Titre"; // Définit le titre de la page
    }
}
</script>

<style lang="css" scoped>

</style>