<template>
    <div class="fond-arrière_compta">
        <test_navbar_poste></test_navbar_poste>
        <test_tableau></test_tableau>

    </div>
</template>

<script>
import test_navbar_poste from '../Poste_de_garde/test_navbar_poste.vue';
import test_tableau from './test_tableau.vue';

export default {
    components: {
        test_navbar_poste,
        test_tableau,

    },
}

</script>

<style lang="css" scoped>
.fond-arrière_compta {
    /* background-color: #E7D2DF; */
    position: absolute;
    width: 100%;
    /* Utilisation de % pour la largeur */
    min-height: 150vh;
    /* Utilisation de vh pour la hauteur minimale */
    box-sizing: border-box;
    /* Inclure le padding et la bordure dans la hauteur et la largeur */
    padding: 2%;
    /* Ajouter un padding pour l'espacement */
    /* Vous pouvez ajuster le padding, la marge ou la largeur en fonction de vos besoins */

    /* Ajustez cette valeur pour correspondre à la largeur de votre nav_bar_gauche */
    box-sizing: border-box
}

/* Media query pour les styles responsives */
@media screen and (max-width: 768px) {
    .fond-arrière_compta {
        padding: 1%;
        /* Réduire le padding pour les petits écrans */
    }
}
</style>