<template>
    <div class="fond-arrière">
        <Fond_gauche_affiche_cli></Fond_gauche_affiche_cli>
       
           
        <!-- Appel de InformationEquipe avec la prop "joueurs" -->
        <informationEquipe :joueurs="listeJoueurs" />  
    </div>
   
</template>

<script>
import Fond_gauche_affiche_cli from './Fond_gauche_affiche_cli.vue'
import informationEquipe from './informationEquipe.vue'
import axios from'axios'

export default{
    components:{
       Fond_gauche_affiche_cli,
        informationEquipe,
    },
    data() {
        return {
            listeJoueurs: [], // Déclarez la propriété listeJoueurs
        };
    },
    mounted() {
        // Exemple de récupération des joueurs depuis une API (axios)
        axios.get('http://127.0.0.1:8000/api/equipes')
            .then(response => {
                this.listeJoueurs = response.data.joueurs; // Assurez-vous que la structure de votre réponse est correcte
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des joueurs :', error);
            });
    },
};
</script>
<style lang="css" scoped>
    .fond-arrière {
        background-color: white;
        width: 100%;       /* Utilisation de % pour la largeur */
        min-height: 100vh; /* Utilisation de vh pour la hauteur minimale */
        box-sizing: border-box; /* Inclure le padding et la bordure dans la hauteur et la largeur */
        padding: 2%;       /* Ajouter un padding pour l'espacement */
          /* Vous pouvez ajuster le padding, la marge ou la largeur en fonction de vos besoins */
    padding-left: 20%;  /* Ajustez cette valeur pour correspondre à la largeur de votre nav_bar_gauche */
    box-sizing: border-box
    }

    /* Media query pour les styles responsives */
    @media screen and (max-width: 768px) {
        .fond-arrière {
            padding: 1%;   /* Réduire le padding pour les petits écrans */
        }
    }
</style>

