<template>
    <div class="cadre_admin_liste">
        <h3 class="t_listePerso">Listes des personnelles</h3>
        <div class="p_cadre_admin_liste">
            <table>
                <tr>
                    <td class="nom-utilisateur">Nom utilisateurs</td>
                    <td class="e_mail">E_mail</td>
                    <td class="poste">Rôle</td>
                    <td class="vide"></td>
                </tr>
                <tr v-for="user in personelle" :key="user.id">
                    <td class="centrer"><input type="text" :value=" user.name" readonly></td>
                    <td class="centrer"><input type="text" :value=" user.email" readonly></td>
                    <td class="centrer"><input type="text" :value=" user.typeUtilisateur" readonly></td>
                    <td class="centrer"><input class="btn_supp" type="button" @click="deleteUser(user.id)" value="Supprimer"></td>
                </tr>
            </table>
        </div>
            <!-- The Modal -->
    <div class="modal fade" id="myModal">
        <div class="modal-dialog">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h5 class="modal-title">Veuillez choisir le choix de connexion :</h5>

                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>

                <!-- Modal body -->
               <div class="modal-body">
                    <div class="d-flex justify-content" @click="RedirectToAdmin">
                        <img class="img-fluid ico-profil ms-5 me-3" src="../../assets/Icon/profil_admin.svg" alt="">
                        <p @click="RedirectToAdmin" data-bs-dismiss="modal">Administrateur</p>
                    </div>
                    <div class="d-flex justify-content">
                        <img class="img-fluid ico-profil ms-5 me-3" src="../../assets/Icon/profil_compta.svg" alt="">
                        <p @click="RedirectToComptable" data-bs-dismiss="modal">Comptable</p>
                    </div>
                    <div class="d-flex justify-content">
                        <img class="img-fluid ico-profil ms-5 me-3" src="../../assets/Icon/profil_poste.svg" alt="">
                        <p @click="RedirectToPoste_de_garde" data-bs-dismiss="modal">Poste de garde</p>
                    </div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default{
    data() {
        return {
            personelle: []
        };
    },
       mounted() {
        this.fetchPersonelle();
    },
      methods: {
 
           async fetchPersonelle() {
            try {
                const response = await axios.get('http://127.0.0.1:8000/api/Personelle');
                const data = response.data;
               
                this.personelle = data; // Assurez-vous que la réponse contient les données directement
                console.log(this.personelle); // Vérifiez le format des données dans la console
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        },
        async deleteUser(userId) {
    // Afficher une boîte de dialogue de confirmation
    const confirmation = confirm('Voulez-vous vraiment supprimer cet utilisateur ? Cette action est irréversible.');

    // Si l'utilisateur clique sur "Annuler", ne pas continuer
    if (!confirmation) {
        return;
    }

    try {
        await axios.delete(`http://127.0.0.1:8000/api/DeleteUser/${userId}`);
        this.fetchPersonelle(); // Recharge la liste après suppression
        alert('Utilisateur supprimé avec succès');
    } catch (error) {
        console.error('Erreur lors de la suppression de l\'utilisateur :', error);
        alert('Erreur lors de la suppression de l\'utilisateur.');
    }
}

      }
}

</script>


<style scoped>
.cadre_admin_liste {
    display: inline-block;
    vertical-align: top;
    margin-top: 8%;
    top: 0;
    left: 0;
    width: 102.5%;
    background-color: white;
    border-radius: 10px;
    padding: 2%;
}

.p_cadre_admin_liste {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    text-align: center;
    padding: 3% 3% 3% 3%;
}

table {
    width: 100%;
}



/* Définir la largeur pour chaque colonne */
.nom-utilisateur {
    width: 40%;
    border: none;
    text-align: center;
    /* Exemple de largeur */
}

.e_mail {
    width: 30%;
    border: none;
    text-align: center;
    /* Exemple de largeur */
}

.poste {
    width: 20%;
    border: none;
    text-align: center;
    /* Exemple de largeur */
}

.vide {
    width: 30%;
    border: none;
}


.centrer {
    text-align: center;
    border: none;
}

input {
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    color: white;
    background-color: #D9D9D9;
}

.btn_supp {
    text-align: center;
    border: none;
    border-radius: 10px;
    background-color: #41A62A;
    color: white;
}

.t_listePerso {
    margin-bottom: 2%;
}
input {
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    color: black; /* Change la couleur du texte en noir */
   background-color: #D9D9D9;
    font-weight: bold; /* Rend le texte en gras */
    text-align: center; /* Centrer le texte à l'intérieur de l'input */
}
</style>
