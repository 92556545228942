import { createApp } from 'vue';
import App from './App.vue';
import router from './components/router';
import 'tailwindcss/tailwind.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser, faSignOutAlt, faCalendar ,faTrash} from '@fortawesome/free-solid-svg-icons';  // Ajouter faSignOutAlt ici
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// Ajouter les icônes à la bibliothèque
library.add(faUser, faSignOutAlt, faCalendar,faTrash);

// Configurer Axios (optionnel)
axios.defaults.baseURL = 'http://localhost:8000/api';

const app = createApp(App);

// Initialisation de AOS
AOS.init();

// Utiliser le router
app.use(router);

// Enregistrer FontAwesome globalement
app.component('font-awesome-icon', FontAwesomeIcon);

// Monter l'application
app.mount('#app');
