<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-lg-6 text-center bg_connex d-none d-lg-block">
                <img class="img-fluid logo" src="../../assets/image/A5_-_1-removebg-preview.png" alt="">
                <h1>Se connecter</h1>
                <p class="text">Vous devez être membres de la Commission Sport pour vous connecter</p>
            </div>
            <div class="col-12 col-lg-6  d-flex justify-content-center ">

                <form @submit.prevent="login">

                    <h1 class="m-5 text-center">Connexion</h1>
                    <div class="bordure1 mb-5">
                        <h5 class="l_email">E_mail</h5>
                        <input class="inpt" type="email" name=""  v-model="user.email" placeholder="commissionSport@gmail.com" >
                    </div>
                    <div class="bordure1 ">
                        <h5 class="l_email">Mot de passe</h5>
                        <input class="inpt" type="password" name=""  v-model="user.password" placeholder="Mot de passe">
                    </div>

                    <p class="mdp_oublier mt-3 light"><a href="">Mot de passe oublier?</a></p>
                    <button class="btn bg_fond mt-4" type="submit">Connexion</button>
                    <p class="mt-3"><span class="light">Vous n'avez pas de compte?</span> <a href="">Inscivez vous !</a>
                    </p>

                </form>
            </div>
        </div>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import axios from '../../services/axios_config';
export default {
    data() {
        return {
            user: {
                email: '',
                password: ''
            },
            error: null,
            loading: false // Variable pour suivre l'état de chargement
        };
    },
    methods: {
        
        async login() {
            this.loading = true; // Activer le loader
            this.error = null; // Réinitialiser les 
            try {
                const response = await axios.post('https://terrain.projetphoenix.net/api/Login', this.user);
                console.log('Connexion réussie:', response.data);

                // Vérifie si la réponse contient un jeton
                if (response.data.token) {
                    localStorage.setItem('token', response.data.token);
                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
                    if (response.data.user && response.data.user.typeUtilisateur === 'admin') {
                        this.$router.push('/Admin');// Rediriger vers la route appropriée
                    } else {
                        // Gérer le cas où l'utilisateur n'a pas le bon type d'utilisateur
                        alert('Vous n\'avez pas les permissions nécessaires pour accéder à cette page.');
                        // Vous pouvez rediriger vers une autre page ou faire d'autres actions nécessaires
                    }
                    // Redirection après une connexion réussie
                    // alert('Connexion réussie. Cliquez sur OK pour continuer.');
                    // this.$router.push('/Admin');
                } else {
                    throw new Error('Le jeton d\'authentification est manquant dans la réponse.');
                }
            } catch (error) {
                console.error('Erreur de connexion:', error);
                if (error.response) {
                    this.error = error.response.data.error || 'Une erreur s\'est produite lors de la connexion.';
                } else {
                    this.error = 'Une erreur s\'est produite lors de la connexion.';
                }
                alert('Vous n\'êtes pas inscrit ou votre information est incorrecte');
            }
             finally {
                this.loading = false; // Désactiver le loader dans tous les cas
            }
        }
    }
}


</script>

<style scoped>
.logo {
    width: 30%;
    padding-top: 200px;
}

.bg_connex {
    background-color: #566480;
    height:100vh;
}

.bordure1 {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 4%;
    padding-left: 10%;
    width: 100;
    /* margin-left: -25%; */
}

.l_email {
    /* position: absolute;
    margin-top: -35px; */
    color: #41A62A;
}

.inpt {
    /* width: 100%; */
    outline: none;
    border: none;
}

.bg_fond {
    color: white;
    background-color: #41A62A;
    width: 100%;
}

.inpt-radio {
    height: 0%;

}

.mdp_oublier {
    /* margin-left: -25%; */
}

a {
    text-decoration: none;
}

.light {
    font-weight: lighter;
}
</style>