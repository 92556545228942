
<template>
    <div class="g_cadre">
        <div class="tous_les_form">
            <div class="form1">
                <div>
                    <h1>Vos informations</h1>
                    <p class="texte_Update">les champs sont toujours possible a écrire<br />
                        cliquer sur Enregistrer pour envoyer et sauvegarder vos modifications</p>
                    <p class="texte_Update"></p>
                </div>
                <div class="form_d">
                    <!-- Responsable Section -->
                    <div class="cadre_fiel_btn">
                        <div class="fiel_g_resp">
                            <fieldset class="field_info">
                                <legend>Responsable</legend>
                                <input type="text" v-model="responsable.nomResponsable"
                                    placeholder="Nom du responsable">
                            </fieldset>
                        </div>
                        <div class="btn_mod_g_res">
                            <button class="button_mod">Modifier</button>
                        </div>
                    </div>
                    <!-- Téléphone Section -->
                    <div class="cadre_fiel_btn">
                        <div class="fiel_g_tel">
                            <fieldset class="field_info">
                                <legend>Téléphone</legend>
                                <input type="text" v-model="responsable.telResponsable"
                                    placeholder="Téléphone du responsable">
                            </fieldset>
                        </div>
                        <div class="btn_mod_tel">
                            <button class="button_mod">Modifier</button>
                        </div>
                    </div>
                </div>
                <div class="form_g">
                    <!-- Equipe Section -->
                    <div class="cadre_fiel_btn">
                        <div class="fiel_d_equi">
                            <fieldset class="field_info">
                                <legend>Equipe</legend>
                                <input type="text" v-model="equipe.nomEquipe" placeholder="Nom de l'équipe">
                            </fieldset>
                        </div>
                        <div class="btn_d_equi">
                            <button class="button_mod">Modifier</button>
                        </div>
                    </div>
                    <!-- Email Section -->
                    <div class="cadre_fiel_btn">
                        <div class="fiel_d_email">
                            <fieldset class="field_info">
                                <legend>Email</legend>
                                <input type="text" v-model="responsable.emailResponsable"
                                    placeholder="Email du responsable">
                            </fieldset>
                        </div>
                        <div class="btn_d_email">
                            <button class="button_mod">Modifier</button>
                           
                        </div>
                    </div>
                </div>
            </div>
            <div class="form2">
                <fieldset class="liste_cin">
                    <legend>Liste avec CIN</legend>
                    <div v-for="(joueur, index) in joueurs" :key="index">

                        <input type="checkbox" v-model="selectedJoueurs" :value="joueur.id" class="small-checkbox">

                        <input type="text" class="afficheJoueur1" v-model="joueur.nomJoueur"
                            :placeholder="'Nom du joueur ' + (index + 1)">
                        <input type="text" class="afficheJoueur2" v-model="joueur.cinJoueur"
                            :placeholder="'CIN du joueur ' + (index + 1)">
                        <!-- Ajout d'un champ masqué pour l'ID du joueur -->
                        <input type="hidden" v-model="joueur.id">
                        
                    </div>
                     <!-- Afficher les inputs pour les nouveaux joueurs -->
                     <div v-for="(nouveauJoueur, index) in nouveauxJoueurs" :key="index">
                         <input type="checkbox"  class="small-checkbox">

                         <input type="text" class="afficheJoueur1" v-model="nouveauJoueur.nomJoueur" :placeholder="'Nom nouveau joueur ' + (index + 1)" />
                         <input type="text" class="afficheJoueur2" v-model="nouveauJoueur.cinJoueur" :placeholder="'CIN nouveau joueur ' + (index + 1)" />
                     </div>
                     <button @click="addJoueur" class="btn_AjoutJoueur">Ajouter </button>
                     <button @click="saveNouveauxJoueurs" class="btn_Confirmation">Valider </button>
                     
                    

                </fieldset>
                <div class="Btn_Modif"><button type="submit" @click="update" class="suive">Enregistrer</button></div>
                <div class="btnsupr"><button type="button-suprimer" @click="deleteSelected" class="suive">Supprimer</button></div>
                
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import routes from '../router';

export default {
    data() {
        return {
            // isEditingNomResponsable: false,
            // isEditingTelResponsable: false,
            // isEditingEmailResponsable: false,
            // isEditingnomEquipe: false,
            responsable: {
                idResponsable: '', // Remplacez par l'ID du responsable à mettre à jour
                nomResponsable: '',
                telResponsable: '',
                emailResponsable: '',
            },
            equipe: {
                nomEquipe: '',
            },
            joueurs: [
                {
                    id:'',
                    nomJoueur: '',
                    cinJoueur: '',

                }
            ],
            nouveauxJoueurs: [
                {
                    id:'',
                    nomJoueur:'',
                    cinJoueur:'',
                }
            ],
            selectedJoueurs: [], // Tableaux des joueurs sélectionnés
        };
    },
    mounted() {
        this.fetchEquipeData();
    },
    methods: {
        async fetchEquipeData() {
            try {
                const response = await axios.get('http://127.0.0.1:8000/api/equipes', {
                    // headers: {
                    //     Authorization: `Bearer ${this.getToken()}`
                    // }
                });
                const data = response.data;
                this.responsable = data.responsable;
                this.equipe = data.equipes[0];
                this.joueurs = data.joueurs;
                console.log(this.joueurs);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        },
        getToken() {
            // Récupère le jeton depuis le stockage local
            return localStorage.getItem('token');
        },
        enableEditing(field) {
            this[`isEditing${field.charAt(0).toUpperCase() + field.slice(1)}`] = true;
        },
        toggleEditing(field) {
            const editingKey = `isEditing${field.charAt(0).toUpperCase() + field.slice(1)}`;
            this[editingKey] = !this[editingKey];
        },
        update() {

            axios.put('http://localhost:8000/api/responsablesUpadte', {
                nomResponsable: this.responsable.nomResponsable,
                telResponsable: this.responsable.telResponsable,
                emailResponsable: this.responsable.emailResponsable,
                nomEquipe: this.equipe.nomEquipe,
                  joueurs: this.joueurs.map(joueur => ({
                    id: joueur.id,
                    nomJoueur: joueur.nomJoueur,
                    cinJoueur: joueur.cinJoueur
        }))
            }, {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`
                }
            })
                .then(response => {
                    console.log('mis à jour avec succès:', response.data);
                    alert(" Modification Enregistrer avec Succès");
                    // Mettre à jour l'affichage ou effectuer d'autres actions nécessaires
                    this.isEditingNomResponsable = false;
                    this.isEditingTelResponsable = false;
                    this.isEditingEmailResponsable = false;
                })
                .catch(error => {
                    console.error('Erreur lors de la mise à jour :', error.response ? error.response.data : error.message);
                    alert(" il y a un problème lors de l'enregistrement de modification");
                });

        },

        redirectPageAffiche() {
            routes.push('/AfficheReservation');
        },
        async deleteSelected() {
    if (this.selectedJoueurs.length === 0) {
        alert("Veuillez sélectionner au moins un joueur à supprimer.");
        return;
    }

    if (confirm("Êtes-vous sûr de vouloir supprimer les joueurs sélectionnés ?")) {
        try {
            const response = await axios.delete('http://127.0.0.1:8000/api/DeletePlayer', {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`
                },
                data: {
                    ids: this.selectedJoueurs,
                }
            });

            alert(response.data.message);
            // Réactualiser les données après suppression
            this.fetchEquipeData();
            // Vider le tableau des joueurs sélectionnés
            this.selectedJoueurs = [];
        } catch (error) {
            console.error('Erreur lors de la suppression :', error.response ? error.response.data : error.message);
            alert("Il y a eu un problème lors de la suppression des joueurs.");
        }
    }
},
addJoueur() {
    this.nouveauxJoueurs.push({
        id: null,
        nomJoueur: '',
        cinJoueur: ''
    });
},

 saveNouveauxJoueurs() {
  // Envoyer chaque joueur au serveur
  Promise.all(this.nouveauxJoueurs.map(joueur => 
    axios.post('http://127.0.0.1:8000/api/AjoutJoueur', joueur)
  ))
    .then(() => {
      // Traitement après la soumission
      alert('Nouveaux joueurs ajoutés avec succès');
      // Réinitialiser la liste des nouveaux joueurs si nécessaire
      this.nouveauxJoueurs = [];
    })
    .catch(error => {
      console.error('Erreur lors de l\'ajout des nouveaux joueurs', error.response ? error.response.data : error.message);
      alert("Erreur lors de l'ajout des nouveaux joueurs");
    });
}



  
,
   
    

    }
};
</script>



<style scoped>
/* Media Queries pour rendre la page responsive */
/* Media Queries pour rendre la page responsive */
@media only screen and (max-width: 600px) {
    nav ul li {
        display: block;
        margin: 10px 0;

    }
}

/* Styles de base */
.texte_Update {
    font-size: 10px;
    position: relative;
    left: 15px;


}

.liste_j_c {
    display: flex;
}

.liste_j,
.liste_c {
    flex: 1;
    /* Pour qu'ils prennent chacun la moitié de la largeur du conteneur */
}

.liste_j,
.liste_c {
    margin-right: 20px;
    /* Marge à droite de la première div */
}

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.mon_input {
    border: 1px solid;
    border-radius: 5px;
    padding: 1%;
    margin-top: 1%;
    margin-bottom: 1%;
}

.afficheJoueur1 {
    border: 1px solid;
    border-radius: 5px;
    padding: 1%;
    margin-top: 1%;
    margin-bottom: 1%;
}

.afficheJoueur2 {
    border: 1px solid;
    border-radius: 5px;
    padding: 1%;
    margin-top: 1%;
    margin-bottom: 1%;
    margin-left: 20%;
}

.mon_Input {
    border: 1px solid;
    border-radius: 5px;
    padding: 1%;
    margin-top: 1%;
    margin-bottom: 1%;
}

.button_mood_liste {
    background-color: #41A62A;
    color: white;
    width: 15%;
    margin-left: 33%;
}

fieldset {
    width: 70%;
    margin-left: 1%;
    border-radius: 5px;
    padding: 3%;
    font-family: Arial, Helvetica, sans-serif;
    color: #41A62A;
    margin-bottom: 5%;
    border: 1px solid #808080;
    padding-left: 10%;
    max-height: 300px;
    /* Ajustez cette valeur en fonction de la hauteur souhaitée */
    overflow-y: auto;
}

fieldset::-webkit-scrollbar {
    width: 8px;
}

fieldset::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}

fieldset::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}


input {
    border: none;
    color: #808080;
    font-size: large;
}

input:focus {
    outline: none;
}

.field_info {
    display: flex;
    width: 120%;
}

.btn_mod_tel {
    width: 100%;
    margin-top: 8%;
}

.btn_d_email {
    width: 100%;
    margin-top: 8%;
}

.btn_d_equi {
    width: 100%;
    margin-top: 8%;
}

.btnmodif {
    /*margin-right: 50%;*/
    margin-top: 2%;
    height: 70%;
    margin-right:50%;


}
.btnsupr{
    
    margin-bottom: 70%;
    width:90%;
    margin-left: 32%;
    height: 85%;
}

.nom_t_equipe {
    border: 1px solid #808080;
    width: 80%;
    padding: 1%;
    margin: 1% 0 1%;
    color: #808080;
    border-radius: 5px;
}



.cadre_fiel_btn {
    display: flex;
}

.fiel_g_resp {
    flex: 1;
    width: 100%;
}

.fiel_g_tel {
    width: 100%;
}

.fiel_d_equi {
    width: 100%;
}

.fiel_d_email {
    width: 100%;
}

.btn_mod_g_res {
    flex: 1;
    margin-top: 8%;
    /* width: 100%; */
    /* Pour qu'ils prennent chacun la moitié de la largeur du conteneur */
    /* width: 100%; */
}

.fiel_g_resp {
    margin-right: 0px;
    /* Marge à droite de la première div */
}

.btn_mod_g_res {
    margin-left: 0px;
    widows: 50%;
    /* Marge à gauche de la deuxième div */
}

.button_mod {
    width: 50%;
    background-color: #41A62A;
    color: white;
    border-radius: 5px;
    padding: 1%;
}


.logoForm {
    width: 50%;
    margin: 5%;
    margin-left: 22.5%;
}

h1 {
    margin-top: -2%;
}



.tous_les_form {
    float: left;
    /* Pour que chaque div prenne la moitié de la largeur */
    box-sizing: border-box;
    margin: 2%;
    width: 95%;
    margin-left: 5%;
}

.g_titre {
    position: absolute;
    font-size: xx-large;
    font-style: bold;
    margin-left: 12.5%;
    margin-top: -2%;
}


.liste_1 {
    /* padding: 30%; */
    margin: 20%;
    font-size: x-large;
    margin-left: 34%;
    margin-top: 15%;
    color: white;
}

.liste_2 {
    /* padding: 30%; */
    margin: 20%;
    font-size: x-large;
    margin-left: 34%;
    margin-top: 15%;
    color: #41A62A;
}

.liste_3 {
    /* padding: 30%; */
    margin: 20%;
    font-size: x-large;
    margin-left: 34%;
    margin-top: 15%;
    color: #41A62A;
}




.liste_cin {
    width: 74%;
    padding-bottom: 2%;
    margin-left: 0.5%;
    padding-left: 10%;
    padding-right: 0%;
    padding-top: 3%;

}

.form_d {
    float: left;
    width: 35%;
    /* Pour que chaque div prenne la moitié de la largeur */
    box-sizing: border-box;
    /* display: flex; */

}

.form_g {

    float: left;
    width: 50%;
    /* Pour que chaque div prenne la moitié de la largeur */
    box-sizing: border-box;
    padding-left: 15%;
}


.liste_j_c {
    display: flex;
}

.liste_j,
.liste_c {
    flex: 1;
    /* Pour qu'ils prennent chacun la moitié de la largeur du conteneur */
}

.liste_j,
.liste_c {
    margin-right: 20px;
    /* Marge à droite de la première div */
}

.form2 {
    padding-top: 10%;
    margin-left: 0%;
    width:96%;
}

.btn_ajout_client {
    width: 70.5%;
    font-size: small;
    border-radius: 5%;
    border: 1px solid #808080;
    color: #808080;
    margin-left: -0.5%;
    background-color: #C6E4BF;
}


.suive {
    background-color: #41A62A;
    margin-top: -2%;
    margin-left: 36%;
    padding: 1%;
    width: 12%;
    border-radius: 10px;
    border: none;
    color: white;
    font-size: large;
}
.small-checkbox {
    width: 16px;
    height: 16px;
    transform: scale(0.8); /* Ajuste la taille globale */
    margin-right: 8px; /* Espace entre la checkbox et le champ de texte */
}
.btn_AjoutJoueur{
    background-color: #41A62A;
    margin-top: -2%;
    margin-left: 30%;
    height:15%;
    padding: 1%;
    width: 12%;
    border-radius: 10px;
    border: none;
    color: white;
    font-size: large;
}

.btn_Confirmation{
    background-color: #41A62A;
    margin-top: 1%;
    margin-left: 10%;
    height:15%;
    padding: 1%;
    width: 12%;
    border-radius: 10px;
    border: none;
    color: white;
    font-size: large;

}
</style>