<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-lg-6 text-center bg_connex d-none d-lg-block">
                <img class="img-fluid logo" src="../../assets/image/A5_-_1-removebg-preview.png" alt="">
                <h1>S'incrire</h1>
                <p class="text">Veuillez remplir les champs vides</p>
            </div>
            <div class="col-12 col-lg-6  d-flex justify-content-center ">

                <form @submit.prevent="register">

                    <h1 class="m-3 mt-4 ms-5">Inscription</h1>
                    <div class="bordure1 mb-3">
                        <h5 class="l_email">Nom d'utilisateur</h5>
                        <input type="text" v-model="user.name" placeholder="Nom d'Utilisateur">
                        <div v-if="errors.name" class="error">{{ errors.name }}</div>
                    </div>
                    <div class="bordure1 mb-3">
                        <h5 class="l_email">E_mail</h5>
                        <input type="text" v-model="user.email" placeholder="example@gmail.com">
                        <div v-if="errors.email" class="error">{{ errors.email }}</div>
                    </div>
                    <div class="bordure1 mb-3">
                        <h5 class="l_email">Mot de passe</h5>
                        <input type="password" v-model="user.password" placeholder="Mot de passe ">
                        <div v-if="errors.password" class="error">{{ errors.password }}</div>
                    </div>
                    <div class="bordure1 ">
                        <h5 class="l_email">Confirmation mot de passe</h5>
                        <input type="password" v-model="user.password_confirmation" placeholder="Confirmation mot de passe">
                        <div v-if="errors.password_confirmation" class="error">{{ errors.password_confirmation }}</div>
                    </div>

                    <button class="btn bg_fond mt-4" type="submit">Se connecter</button>


                </form>
            </div>
        </div>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import axios from '../../services/axios_config';

export default {
    data() {
        return {
            user: {
                name: '',
                email: '',
                password: '',
                password_confirmation: '',
                typeUtilisateur: 'user',
            },
            error: null,
            errors: {},
            loading: false // Variable pour suivre l'état de chargement
        };
    },
    methods: {
        validateForm() {
            this.errors = {};

            if (!this.user.name) {
                this.errors.name = 'Le nom d\'utilisateur est requis.';
            }

            if (!this.user.email) {
                this.errors.email = 'L\'email est requis.';
            } else if (!this.validEmail(this.user.email)) {
                this.errors.email = 'L\'email n\'est pas valide.';
            }

            // Vérifiez l'unicité de l'email
            return this.checkEmailUnique(this.user.email)
                .then(isUnique => {
                    if (!isUnique) {
                        this.errors.email = 'L\'email est déjà utilisé.';
                    }

                    if (!this.user.password) {
                        this.errors.password = 'Le mot de passe est requis.';
                    } else if (this.user.password.length < 6) {
                        this.errors.password = 'Le mot de passe doit contenir au moins 6 caractères.';
                    }

                    if (this.user.password !== this.user.password_confirmation) {
                        this.errors.password_confirmation = 'Les mots de passe ne correspondent pas.';
                    }

                    return Object.keys(this.errors).length === 0;
                });
        },

        validEmail(email) {
            // Expression régulière simplifiée pour la validation des e-mails
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(String(email).toLowerCase());
        },
        async checkEmailUnique(email) {
            try {
                const response = await axios.get(`http://localhost:8000/api/check-email/${email}`);
                return response.data.isUnique; // Assume true or false
            } catch (error) {
                console.error('Erreur de vérification de l\'email:', error);
                return false;
            }
        },


        async register() {
            this.loading = true; // Activer le loader
             this.error = null; // Réinitialiser les
            if (!this.validateForm()) {
                return;
            }

            try {
                // Envoi des données utilisateur
                const response = await axios.post('http://localhost:8000/api/Register', this.user);

                // Vérifie si la réponse contient un jeton
                if (response.data.token) {
                    // Stockez le jeton dans localStorage
                    localStorage.setItem('token', response.data.token);

                    // Utilisation du jeton d'accès dans les en-têtes de toutes les requêtes ultérieures avec Axios
                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;

                    // Redirection après une inscription réussie
                    alert('Votre inscription a été réussie. Cliquez sur OK pour continuer.');
                    this.$router.push('/formulairecli');
                } else {
                    throw new Error('Le jeton d\'authentification est manquant dans la réponse.');
                }
            } catch (error) {
                console.error('Erreur d\'inscription:', error);
                if (error.response) {
                    if (error.response.status === 422) {
                        // Les erreurs de validation sont renvoyées avec un statut 422
                        this.errors = error.response.data.errors;
                    } else {
                        this.error = error.response.data.error || 'Une erreur s\'est produite lors de l\'inscription.';
                    }
                } else {
                    this.error = 'Une erreur s\'est produite lors de l\'inscription.';
                }
            }
            finally {
            this.loading = false; // Désactiver le loader dans tous les cas
        }
        }
    },
    created() {
        const token = localStorage.getItem('token');
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
    }
}
</script>

<style scoped>
.logo {
    width: 30%;
    padding-top: 200px;
}

.bg_connex {
    background-color: #566480;
    height: 100vh;
}

.bordure1 {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 4%;
    padding-left: 10%;
    width: 100%;
    /* margin-left: -25%; */
}

.l_email {
    color: #41A62A;
}

input {
    width: 100%;
    outline: none;
    border: none;
}

.bg_fond {
    color: white;
    background-color: #41A62A;
    width: 100%;
}

.inpt-radio {
    height: 0%;

}

.mdp_oublier {
    margin-left: -25%;
}

a {
    text-decoration: none;
}

.light {
    font-weight: lighter;
}
</style>